<template>
  <div class="main-card mb-3 card" :class="{'load-bar' : isSearching}">
    <div class="card-body">  
      <h5 class="card-title">
        User Affiliate
      </h5>
      <div class="search-wrap">
        <div class="search-item">
          <label>Code</label>
          <input
            id="search-id"
            type="text"
            v-model="query.code"
            @keyup.enter="search"
          />
        </div>
        <div class="search-item d-flex">
          <button class="btn btn-primary" @click.prevent="search">{{$t('TEXT_SEARCH')}}</button>
        </div>
      
      </div>
    </div>
     <div class="card-body" :class="{ 'loading': isSearching }">
        <binary-tree-expandable/>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import BinaryTreeExpandable from '@/views/components/BinaryTreeExpandable'
import FilterData from "@/mixin/FilterData"
import NoData from '../components/NoData'
import Sticky from 'vue-sticky-directive'
export default {
  mixins: [FilterData],
  components: {
    NoData,
    BinaryTreeExpandable
  },
  directives: {Sticky},
  data() {
    return {
      isSearching: false,
      affData: []
    };
  },
  computed: {
    wallets () {
      return this.$store.getters.wallets
    },
    broker() {
      return this.$store.getters.broker;
    }
  },
  created() {
    const initQuery = {}
    const query = JSON.parse(JSON.stringify(this.$route.query));
    this.query = Object.assign(initQuery, query)
    // this.query.code && this.fetch();
  },
  methods: {
    formatNumber(value) {
      let newNumber = value.toString().split(".")
      newNumber[0] = newNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return newNumber.join(".")
    },
    fetch () {
      let filterObj = this.buildQueryObj()
      this.isSearching = true
      const code = this.$route.query.code || filterObj.code
      if (!code) return
      this.$store.dispatch('getUserAffTree', { code }).then(rs => {
        this.isSearching = false
      })
      // this.$store.dispatch('getUserAffTree', filterObj).then((rs) => {
      //   console.log(rs)
      //   this.affData = rs.data[this.query['level']]
      //   this.isSearching = false
      // })
    },
    exportExcelBalance () {
      this.exportExcel('balance')
    }
  }
}
</script>