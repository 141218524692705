
import CONFIG from '@/config/Config'
import { Get, Post } from '@/utils/RequestService'

var apiUrl = CONFIG.API_POINT
var service = {
  // Page OTC Fiat
  getOtcFiat: (params) => Get(`${apiUrl}/otc/list-otc-fiat`, params),
  updateOtcFiat: data => Post(`${apiUrl}/otc/update-otc-fiat`, data),
  addOtcFiat: data => Post(`${apiUrl}/otc/add-otc-fiat`, data),
  // Page OTC Country
  getOtcCountry: (params) => Get(`${apiUrl}/otc/list-otc-country`, params),
  updateOtcCountry: data => Post(`${apiUrl}/otc/update-otc-country`, data),
  addOtcCountry: data => Post(`${apiUrl}/otc/add-otc-country`, data),
  getListFiatByBroker: (params) => Get(`${apiUrl}/public/list-code-fiat-by-broker/${params.broker}`),
  // Page OTC Curency
  getOtcCurrency: (params) => Get(`${apiUrl}/otc/list-otc-currency`, params),
  updateOtcCurrency: data => Post(`${apiUrl}/otc/update-otc-currency`, data),
  addOtcCurrency: data => Post(`${apiUrl}/otc/add-otc-currency`, data),
  // Page Payment Method
  getListOtcPaymentMethod: (params) => Get(`${apiUrl}/otc/list-otc-payment-method`, params),
  updateOtcPaymentMethod: data => Post(`${apiUrl}/otc/update-otc-payment-method`, data),
  addOtcPaymentMethod: data => Post(`${apiUrl}/otc/add-otc-payment-method`, data),
  // Page System Config
  getListOtcSystemConfig: (params) => Get(`${apiUrl}/otc/list-otc-system-config`, params),
  updateOtcSystemConfig: data => Post(`${apiUrl}/otc/update-otc-system-config`, data),
  addOtcSystemConfig: data => Post(`${apiUrl}/otc/add-otc-system-config`, data),
  getListBrokerFilter: (params) => Get(`${apiUrl}/public/list-broker-filter`, params),

  // Page OTC Order
  getListOtcOrder: (params) => Get(`${apiUrl}/otc/list-otc-orders`, params),
  getOtcOrderType: (params) => Get(`${apiUrl}/public/list-otc-order-type`, params),
  getOtcOrderStatus: (params) => Get(`${apiUrl}/public/list-otc-order-status`, params),
  getListFiatCode: (params) => Get(`${apiUrl}/public/list-code-fiat-by-broker/${params.broker}`),
  // Page OTC Advance
  getListOtcAdvance: (params) => Get(`${apiUrl}/otc/list-otc-adv`, params),

  //Page OTC Users
  getListOtcUsers: (params) => Get(`${apiUrl}/otc/list-otc-users`, params),
  updateOtcUserStatus: data => Post(`${apiUrl}/otc/update-otc-user-status/${data.broker}`, data),
}
export default service