import CONFIG from '@/config/Config'
import { Get, Post } from '@/utils/RequestService'
var apiUrl = CONFIG.API_POINT
var service = {
  getWallets: (params) => Get(`${apiUrl}/balance/list-balance-user`, params),
  getTransactions: (page, params) => Get(`${apiUrl}admin/transaction-history/${page}/20`, params),
  getTranfers: (params) => Get(`${apiUrl}/wallet/list-transfer-balance`, params),
  getOrderStatus: () => Get(`${apiUrl}/public/list-order-status`),
  getTransactionsStatus: () => Get(`${apiUrl}config/transaction-status`),
  getTransactionConfirmStatus: () => Get(`${apiUrl}config/transaction-status-confirm`),
  getTransactionsType: () => Get(`${apiUrl}config/transaction-type`),
  getTransferStatus: () => Get(`${apiUrl}/public/list-transfer-status`),
  getTransferType: () => Get(`${apiUrl}/public/list-transfer-type`),
  // getBalanceOverView: (params) => Get(`${apiUrl}admin/dashboard`),
  getBalanceOverView: (params) => Get(`${apiUrl}admin/dashboard-v2`),
  getTokenRemanining: () => Get(`${apiUrl}/balance/token-remaining`),
  getSymbols: (params) => Get(`${apiUrl}/public/list-symbol-by-broker/${params.broker}`),
  getTransactionByHash: (hash) => Get(`${apiUrl}admin/scan-hash/${hash}`),

  // Wallet API Key Config
  getListWalletUserApiKey: (params) => Get(`${apiUrl}/wallet-user-api-key/list-wallet-user-api-key`, params),
  addWalletUserApiKey: (data) => Post(`${apiUrl}/wallet-user-api-key/add-wallet-user-api-key`, data),
  updateWalletUserApiKey: (data) => Post(`${apiUrl}/wallet-user-api-key/update-wallet-user-api-key/${data.broker}`, data),

  confirmTransactionWithdraw: (data) => Post(`${apiUrl}admin/confirm-transaction-withdraw`, data),

  // Wallet
  getListWallets: (params) => Get(`${apiUrl}/wallet/list-wallet`, params),
}
export default service
