<template>
  <div>
    <Header/>
    <ThemeSetting/>
    <div class="app-main">
      <SideBar/>
      <div class="app-main__outer fruid-width">
        <DashboardContent/>
        <Footer/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import SideBar from './components/SideBar'
import ThemeSetting from './components/ThemeSetting'
import DashboardContent from './DashboardContent'
export default {
  components: {
    Header,
    SideBar,
    Footer,
    ThemeSetting,
    DashboardContent
  },
  created () {
    this.$store.dispatch('getUserInfo')
  }
}
</script>

<style lang="scss" scoped>
.app-main__outer {
  max-width: 100%;
}
</style>