import CONFIG from '@/config/Config'
import { Get, Post } from '@/utils/RequestService'

const apiUrl = CONFIG.API_POINT

var service = {
  getBrokers: params => Get(`${apiUrl}/system/broker/list-broker`, params),
  getBrokerConfig: params => Get(`${apiUrl}/broker/get-config`, params),
  getBrokerInfo: () => Get(`${apiUrl}/broker/get-info`),
  getListCurrency: () => Get(`${apiUrl}/config/list-currency`),
  getListAllCurrency: () => Get(`${apiUrl}admin/hash-rate-currencies`),
  addCurrency: (data) => Post(`${apiUrl}/system/currency/add-currency`, data),
  getBrokersCurrency: (params) => Get(`${apiUrl}/broker-currency/list-broker-currency`, params),
  getBrokersLanguage: () => Get(`${apiUrl}/broker/get-list-language`),
  // getBrokersSymbol: () => Get(`${apiUrl}/broker/get-list-symbol`),
  getBrokersWithdraw: () => Get(`${apiUrl}/broker/get-list-withdraw-level`),
  getBrokersUser: () => Get(`${apiUrl}/broker/get-list-withdraw-level`),
  saveConfig: data => Post(`${apiUrl}/broker/save-config`, data),
  updateBrokerCurrencyStatus: data => Post(`${apiUrl}/broker-currency/update-broker-currency-status/${data.broker}`, data),
  updateBrokerCurrency: data => Post(`${apiUrl}/broker-currency/update-broker-currency/${data.broker}`, data),
  addBrokerCurrency: data => Post(`${apiUrl}/broker-currency/create-broker-currency`, data),
  getBrokersSymbol: params => Get(`${apiUrl}/broker-symbol/list-broker-symbol`, params),
  addBrokerSymbol: data => Post(`${apiUrl}/broker-symbol/create-broker-symbol`, data),
  updateBrokerSymbol: data => Post(`${apiUrl}/broker-symbol/update-broker-symbol`, data),
  updateBrokerSymbolStatus: data => Post(`${apiUrl}/broker-symbol/update-broker-symbol-status`, data),
  getListSymbol: () => Get(`${apiUrl}/public/list-symbol-filter`),
  getListSymbols: () => Get(`${apiUrl}/system/symbol/list-symbols`),
  getListCurrencyFilter: () => Get(`${apiUrl}/public/list-currency-filter`),
  addSymbol: (data) => Post(`${apiUrl}/system/symbol/add-symbol`, data),
  getBrokersConfig: params => Get(`${apiUrl}/broker-config/list-broker-config`, params),
  getListFeeType: () => Get(`${apiUrl}/public/list-fee-type`),
  updateBrokerConfigStatus: data => Post(`${apiUrl}/broker-config/update-broker-config-status/${data.broker}`, data),
  updateBrokerConfig: data => Post(`${apiUrl}/broker-config/update-broker-config`, data),
  updateCoinInfo: data => Post(`${apiUrl}/coin-info/update-coin-info`, data),
  updateLimitOrder: data => Post(`${apiUrl}/limit-order/update-limit-order`, data),
  addBrokerConfig: data => Post(`${apiUrl}/broker-config/create-broker-config`, data),
  getListBrokerWithdrawLevel: params => Get(`${apiUrl}/broker-withdraw-level/list-broker-withdraw-level`, params),
  updateBrokerWithdrawLevel: data => Post(`${apiUrl}/broker-withdraw-level/update-broker-withdraw-level`, data),
  addCoin: data => Post(`${apiUrl}/coin-info/add-coin-info`, data),
  addLimitOrder: data => Post(`${apiUrl}/limit-order/add-limit-order`, data),
  getListCoinInfo: () => Get(`${apiUrl}/coin-info/list-coin-info`),
  getListLimitOrder: () => Get(`${apiUrl}/limit-order/list-limit-order`),

  // Brokers Account Config
  getBrokersAccount: (params) => Get(`${apiUrl}/broker-account/list-broker-account-config`, params),
  addBrokerAccount: data => Post(`${apiUrl}/broker-account/add-broker-account-config`, data),
  updateBrokerAccount: data => Post(`${apiUrl}/broker-account/update-broker-account-config`, data),

  // Brokers Wallet Config
  getBrokersWallet: (params) => Get(`${apiUrl}/broker-wallet/list-broker-wallet-config`, params),
  addBrokerWallet: data => Post(`${apiUrl}/broker-wallet/add-broker-wallet-config`, data),
  updateBrokerWallet: data => Post(`${apiUrl}/broker-wallet/update-broker-wallet-config`, data),
  
  getListBrokerCurrencyExchangeUsdt: () => Get(`${apiUrl}/broker-currency-exchange-usdt/get-list`),
  addBrokerCurrencyExchangeUsdt: data => Post(`${apiUrl}/broker-currency-exchange-usdt/add`, data),
  updateBrokerCurrencyExchangeUsdt: data => Post(`${apiUrl}/broker-currency-exchange-usdt/update`, data),

  get: data => Post(`${apiUrl}/broker-currency-exchange-usdt/update`, data),
  updateBrokerCurrencyExchangeUsdt: data => Post(`${apiUrl}/broker-currency-exchange-usdt/update`, data),

}

export default service
