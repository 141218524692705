<template>
  <div class="main-card mb-3 card" :class="{'load-bar' : isSearching}">
    <div class="card-body">
      <h5 class="card-title">{{$t('TEXT_USER_MANAGEMENT')}}</h5>
      <div class="search-wrap">
        <div class="search-item">
          <label>Email / Invite Code / Username</label>
          <input
            id="search-id"
            type="text"
            v-model="query.email"
            @keyup.enter="search"
          />
        </div>
        <div class="search-item d-flex">
          <button class="btn btn-primary" @click.prevent="search">{{$t('TEXT_SEARCH')}}</button>
        </div>
      </div>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Balance" name="user-info">
          <div class="classic-table" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-1">Currency</div>
                <div class="flex-6">Balance</div>
              </dt>
              <template v-if="usersInfo.length">
                <dd v-for="userInfo in usersInfo">
                  <div class="flex-1" >{{userInfo.currency}}</div>
                  <div class="flex-6">{{userInfo.balance}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Buy History" name="first">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-1">No</div>
                <div class="flex-2">Token mining</div>
                <div class="flex-2">Amount usd</div>
                <div class="flex-2">Price</div>
                <div class="flex-2">Power Minting</div>
                <div class="flex-2">Token Bonus</div>
                <div class="flex-2">Power Minting Bonus</div>
                <div class="flex-3">Date</div>
              </dt>
              <template v-if="buyHistory.length">
                <dd v-for="(item, index) in buyHistory" :key="item.id">
                <div class="flex-1">{{index + 1}}</div>
                  <div class="flex-2">{{item.tokenMining}}</div>
                  <div class="flex-2">{{item.amountUsd}}</div>
                  <div class="flex-2">{{item.price}}</div>
                  <div class="flex-2">{{item.amountPower}}</div>
                  <div class="flex-2">{{ item.tokenBonus ? (`${item.tokenBonus} (${item.symbolBonus})`) : '' }}</div>
                  <div class="flex-2">{{item.bonusPps}}</div>
                  <div class="flex-3">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="buyHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="User Overview" name="third">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-3">Action</div>
                <div class="flex-3">Email</div>
                <div class="flex-3">Email Ref</div>
                <div class="flex-3">Code</div>
                <div class="flex-2">Aleo mining</div>
                <div class="flex-2">Iron mining</div>
                <div class="flex-2">Kas mining</div>
                <div class="flex-2">Total buy</div>
                <div class="flex-2">Total com ref Aleo</div>
                <div class="flex-2">Total com ref Usdt</div>
                <div class="flex-2">Total com brand Aleo</div>
                <div class="flex-2">Total com brand Usdt</div>
              </dt>
              <template v-if="userComsHistory">
                <dd>
                  <div class="flex-3">
                    <div><a href="javascript:;" @click.prevent="onChangeEmail(userComsHistory)">Change Email</a>&nbsp;</div>
                    <div><a href="javascript:;" @click.prevent="viewTree(userComsHistory)">View Tree</a></div>
                  </div>
                  <div class="flex-3">{{userComsHistory.email}}</div>
                  <div class="flex-3">{{userComsHistory.refEmail}}</div>
                  <div class="flex-3">{{userComsHistory.code}}</div>
                  <div class="flex-2">{{userComsHistory.aleoMining}}</div>
                  <div class="flex-2">{{userComsHistory.ironMining}}</div>
                  <div class="flex-2">{{userComsHistory.kasMining}}</div>
                  <div class="flex-2">{{userComsHistory.totalBuy}}</div>
                  <div class="flex-2">{{userComsHistory.totalComRefA}}</div>
                  <div class="flex-2">{{userComsHistory.totalComRefU}}</div>
                  <div class="flex-2">{{userComsHistory.totalComBrandA}}</div>
                  <div class="flex-2">{{userComsHistory.totalComBrandU}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
          </div>
        </el-tab-pane>

        <!-- Transaction history -->
        <el-tab-pane label="Transaction History" name="transaction">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-1">Symbol</div>
                <div class="flex-1">Amount</div>
                <div class="flex-2">Hash</div>
                <div class="flex-2">Type</div>
                <div class="flex-2">Status</div>
                <div class="flex-2">Time</div>
              </dt>
              <template v-if="transactionHistory.length">
                <dd v-for="item in transactionHistory" :key="item.id">
                  <div class="flex-1">{{item.currency}}</div>
                  <div class="flex-1">{{item.amount}}</div>
                  <div class="flex-2" @click="copy(item.hash)">{{item.beautyHash}}</div>
                  <div class="flex-2">{{item.type}}</div>
                  <div class="flex-2">{{item.status}}</div>
                  <div class="flex-2">{{item.beautyTime}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="transactionHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>

        <!-- Coms -->
        <el-tab-pane label="Mining" name="com1">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-2">Amount</div>
                <div class="flex-2">Mining Rate</div>
                <div class="flex-2">Symbol</div>
                <div class="flex-2">Percent</div>
                <div class="flex-2">Power</div>
                <div class="flex-2">Created At</div>
              </dt>
              <template v-if="commissionAleoHistory.length">
                <dd v-for="item in commissionAleoHistory" :key="item.id">
                  <div class="flex-2">{{item.amount}}</div>
                  <div class="flex-2">{{item.rateMining}}</div>
                  <div class="flex-2">{{item.symbol}}</div>
                  <div class="flex-2">{{item.beautyPercent}}%</div>
                  <div class="flex-2">{{item.totalPower}}</div>
                  <div class="flex-2">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="commissionAleoHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="USDT Referral" name="com2_usdt">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-2">Username</div>
                <div class="flex-2">Amount</div>
                <div class="flex-2">Percent</div>
                <div class="flex-2">Commission</div>
                <div class="flex-2">Level</div>
                <div class="flex-2">Symbol</div>
                <div class="flex-2">Date</div>
              </dt>
              <template v-if="commissionHistory.length">
                <dd v-for="item in commissionHistory" :key="item.id">
                  <div class="flex-2">{{item.fromUser}}</div>
                  <div class="flex-2">{{item.fromAmount}}</div>
                  <div class="flex-2">{{item.percent * 100}}%</div>
                  <div class="flex-2">{{item.amount}}</div>
                  <div class="flex-2">{{item.level}}</div>
                  <div class="flex-2">{{item.symbol}}</div>
                  <div class="flex-2">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="commissionHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Aleo Referral" name="com2_aleo">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-2">Username</div>
                <div class="flex-2">Amount</div>
                <div class="flex-2">Percent</div>
                <div class="flex-2">Commission</div>
                <div class="flex-2">Level</div>
                <div class="flex-2">Symbol</div>
                <div class="flex-2">Date</div>
              </dt>
              <template v-if="commissionHistory.length">
                <dd v-for="item in commissionHistory" :key="item.id">
                  <div class="flex-2">{{item.fromUser}}</div>
                  <div class="flex-2">{{item.fromAmount}}</div>
                  <div class="flex-2">{{item.percent * 100}}%</div>
                  <div class="flex-2">{{item.amount}}</div>
                  <div class="flex-2">{{item.level}}</div>
                  <div class="flex-2">{{item.symbol}}</div>
                  <div class="flex-2">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="commissionHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Kas Referral" name="com2_kas">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
                <div class="flex-2">Username</div>
                <div class="flex-2">Amount</div>
                <div class="flex-2">Percent</div>
                <div class="flex-2">Commission</div>
                <div class="flex-2">Level</div>
                <div class="flex-2">Symbol</div>
                <div class="flex-2">Date</div>
              </dt>
              <template v-if="commissionHistory.length">
                <dd v-for="item in commissionHistory" :key="item.id">
                  <div class="flex-2">{{item.fromUser}}</div>
                  <div class="flex-2">{{item.fromAmount}}</div>
                  <div class="flex-2">{{item.percent * 100}}%</div>
                  <div class="flex-2">{{item.amount}}</div>
                  <div class="flex-2">{{item.level}}</div>
                  <div class="flex-2">{{item.symbol}}</div>
                  <div class="flex-2">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="commissionHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>

        <el-tab-pane label="USDT Rank" name="com3_usdt">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
               <div class="flex-2">Username</div>
                <div class="flex-2">Amount</div>
                <div class="flex-2">Percent</div>
                <div class="flex-2">Commission</div>
                <div class="flex-2">Level</div>
                <div class="flex-2">Symbol</div>
                <div class="flex-2">Date</div>
              </dt>
              <template v-if="commissionBrandHistory.length">
                <dd v-for="item in commissionBrandHistory" :key="item.id">
                  <div class="flex-2">{{item.fromUser}}</div>
                  <div class="flex-2">{{item.fromAmount}}</div>
                  <div class="flex-2">{{item.percent * 100}}%</div>
                  <div class="flex-2">{{item.amount}}</div>
                  <div class="flex-2">{{item.role}}</div>
                  <div class="flex-2">{{item.symbol}}</div>
                  <div class="flex-2">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="commissionBrandHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Aleo Rank" name="com3_aleo">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
               <div class="flex-2">Username</div>
                <div class="flex-2">Amount</div>
                <div class="flex-2">Percent</div>
                <div class="flex-2">Commission</div>
                <div class="flex-2">Level</div>
                <div class="flex-2">Symbol</div>
                <div class="flex-2">Date</div>
              </dt>
              <template v-if="commissionBrandHistory.length">
                <dd v-for="item in commissionBrandHistory" :key="item.id">
                  <div class="flex-2">{{item.fromUser}}</div>
                  <div class="flex-2">{{item.fromAmount}}</div>
                  <div class="flex-2">{{item.percent * 100}}%</div>
                  <div class="flex-2">{{item.amount}}</div>
                  <div class="flex-2">{{item.role}}</div>
                  <div class="flex-2">{{item.symbol}}</div>
                  <div class="flex-2">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="commissionBrandHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Kas Rank" name="com3_kas">
          <div class="classic-table large" sticky-container>
            <dl>
              <dt v-sticky sticky-offset="{top: 60}">
               <div class="flex-2">Username</div>
                <div class="flex-2">Amount</div>
                <div class="flex-2">Percent</div>
                <div class="flex-2">Commission</div>
                <div class="flex-2">Level</div>
                <div class="flex-2">Symbol</div>
                <div class="flex-2">Date</div>
              </dt>
              <template v-if="commissionBrandHistory.length">
                <dd v-for="item in commissionBrandHistory" :key="item.id">
                  <div class="flex-2">{{item.fromUser}}</div>
                  <div class="flex-2">{{item.fromAmount}}</div>
                  <div class="flex-2">{{item.percent * 100}}%</div>
                  <div class="flex-2">{{item.amount}}</div>
                  <div class="flex-2">{{item.role}}</div>
                  <div class="flex-2">{{item.symbol}}</div>
                  <div class="flex-2">{{item.beautyCreatedAt}}</div>
                </dd>
              </template>
              <NoData v-else/>
            </dl>
            <el-pagination
              v-if="commissionBrandHistory.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>

    </div>
    <el-dialog
      title="Fake Deposit"
      :visible.sync="dialogVisibleFadeDeposit"
      :closeOnClickModal="false"
      :destroy-on-close="true"
      class="medium"
    >
      <div>
        <form class="mx-auto from-update-broker" id="creatBrokerConfig" method="post" action="" :class="{  }">
          <div class="">
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Amount (USDT)
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text"
                    v-model="form.amount">
            </div>
          </div>
          <div class="from-group text-center out-btn-update">
              <button :disabled="!isValidated" @click.prevent="fakeDeposit()" class="btn btn-primary">{{$t('BTN_CONFIRM')}}</button>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog
      title="Change email"
      :visible.sync="changeEmailVisible"
      class="medium"
      :class="{'loading' : isLoading}"
    >
      <div>
        <form class="mx-auto from-update-broker" id="creatBrokerConfig" method="post" action="">
          <div class="">
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                From Email(*)
              </label>
              <!-- <InputNumber v-model="form.makerFee"/> -->
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                    v-model="changeEmailForm.fromEmail">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                From Username(*)
              </label>
              <!-- <InputNumber v-model="form.takerFee"/> -->
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                    v-model="changeEmailForm.fromUsername">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                New Email(*)
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text"
                    v-model="changeEmailForm.toEmail">
            </div>
          </div>
          <div class="from-group text-center out-btn-update">
              <button :disabled="isLoading || !isValidateChangeEmailForm" @click.prevent="changeEmail()" class="btn btn-primary">{{$t('BTN_CONFIRM')}}</button>
          </div>
        </form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import FilterData from "@/mixin/FilterData";
import NoData from '../components/NoData'
import Sticky from 'vue-sticky-directive'
export default {
  mixins: [FilterData],
  components: {
    NoData
  },
  directives: {Sticky},
  data() {
    return {
      form: {
        amount: ''
      },
      isProcessing: false,
      dialogVisibleFadeDeposit: false,
      showLevel: 'F1',
      activeLevel: [1],
      activeName: 'user-info',
      changeEmailVisible: false,
      changeEmailForm: {
        address: '',
        code:'',
        newAddress: '',
        fromUsername: '',
        fromEmail: '',
        toEmail: ''
      },
      changeMaxoutForm: {
        code:'',
        maxout: ''
      },
      currentUser: {},
      isLoading: false,
      detailUser: {},
      dialogVisible: false,
      isSearching: false
    };
  },
  computed: {
    commissionHistory () {
      return this.$store.getters.commissionHistory
    },
    commissionAleoHistory () {
      return this.$store.getters.commissionAleoHistory
    },
    commissionBrandHistory () {
      return this.$store.getters.commissionBrandHistory
    },
    isValidated () {
      return this.form.amount && this.form.amount.trim() && !isNaN(this.form.amount)
        && parseFloat(this.form.amount) > 0 && !this.isProcessing
    },
    userComsHistory () {
      return this.$store.getters.userComsHistory
    },
    buyHistory () {
      return this.$store.getters.buyHistory
    },
    comRefHistory () {
      return this.$store.getters.comRefHistory
    },
    transactionHistory () {
      return this.$store.getters.transactionHistory
    },
    comDailyRefHistory () {
      return this.$store.getters.comDailyRefHistory
    },
    comDailyHistory () {
      return this.$store.getters.comDailyHistory
    },
    usersInfo () {
      return this.$store.getters.usersInfo
    },
    comHistory () {
      return this.$store.getters.comHistory
    },
    isValidateChangeEmailForm () {
      return this.changeEmailForm.toEmail && this.changeEmailForm.toEmail !== this.changeEmailForm.fromEmail
    },
    isValidateChangeMaxoutForm () {
      return this.changeMaxoutForm.maxout
    },
    pagination () {
      return this.$store.getters.pagination
    },
    stakingDetailHistory () {
      return this.$store.getters.stakingDetailHistory
    },
    userAff () {
      return this.$store.getters.userAff
    },
    userDashboard () {
      return this.$store.getters.userDashboard
    },
  },
  created() {
    this.query = JSON.parse(JSON.stringify(this.$route.query));
    this.fetch();
  },
  watch: {
    'activeName' (newVal, oldVal) {
      if (newVal !== oldVal) {
        const queryObj = this.buildQueryObj()
        queryObj.page = 1
        this.changeRouteParams(queryObj)
      }
    },
  },
  methods: {
    changeEmail () {
      if (!this.isValidateChangeEmailForm) return
      this.isLoading = true
      this.$store.dispatch("changeEmail", this.changeEmailForm).then(rs => {
        this.isLoading = false
      })
    },
    onChangeEmail (item) {
      this.changeEmailVisible = true
      this.changeEmailForm.fromEmail = item.email
      this.changeEmailForm.fromUsername = item.username
    },
    viewTree (item) {
      window.open(`${window.location.origin}/user/tree?code=${item.code}`, '_blank')
    },
    async fakeDeposit () {
      if (!this.isValidated) return
      this.isProcessing = true
      let filterObj = this.buildQueryObj()
      this.form.address = filterObj.address
      const result = await this.$store.dispatch('fakeDeposit', this.form)
      this.isProcessing = false
      if (result.status) {
        this.dialogVisibleFadeDeposit = false
        this.fetch()
        this.form.amount = ''
      }
    },
    onDeposit () {
      this.dialogVisibleFadeDeposit = true
    },
    copy (item) {
      this.$copyText(item).then(e => {
        this.$store.dispatch('showSuccessMsg', this.$t('TEXT_COPIED', 'Copied'))
      })
    },
    fetch() {
      let filterObj = this.buildQueryObj()

      if (!filterObj.email) return
      this.isSearching = true
      switch (this.activeName) {
        case 'user-info':
          this.$store.dispatch("getUsersInfo", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'first':
          this.$store.dispatch("getBuyHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'third':
          this.$store.dispatch("getUserComsOverview", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'second':
          this.$store.dispatch("getComDailyRefHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'staking':
          this.$store.dispatch("getStakingHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'transaction':
          this.$store.dispatch("getTransactionHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'useraff':
          for (let i = 1; i <= 16; i++) {
            this.$store.dispatch("getAffMemberByLevel", { params: filterObj, level: i }).then(() => {
              this.isSearching = false
            });
          }
          break
        case 'useroverview':
          this.$store.dispatch("getUserDashboard", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'com1':
          this.$store.dispatch("getCommissionAleoHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'com2_usdt': case 'com2_aleo': case 'com2_kas':
          filterObj.symbol = this.activeName.split('_')[1]
          this.$store.dispatch("getCommissionRefHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        case 'com3_usdt': case 'com3_aleo': case 'com3_kas':
          filterObj.symbol = this.activeName.split('_')[1]
          this.$store.dispatch("getCommissionBrandHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
        default:
          this.$store.dispatch("getComDailyHistory", { page: filterObj.page, params: filterObj }).then(() => {
            this.isSearching = false
          });
          break
      }
    },
    handleClick(tab, event) {
      window.dispatchEvent(new Event('resize'))
      this.fetch()
    },
    changeAllowLogin(item) {
      this.currentUser = item
      let textActive = !item.isAllowLogin
        ? 'Disable login'
        : 'Enable login'
      this.$confirm(textActive, this.$t('TEXT_WARNING'), {
        confirmButtonText: this.$t('BTN_OK'),
        cancelButtonText: this.$t('BTN_CANCEL'),
        type: "warning"
      })
      .then(() => {
        let data = {
          status: item.isAllowLogin,
          address: this.query.address
        };
        this.$store.dispatch("updateAllowLogin", data)
      })
      .catch(() => {
        this.currentUser.isAllowLogin = !item.isAllowLogin
      })
    },
    changeAllowWithdraw(item) {
      this.currentUser = item
      let textActive = !item.isAllowLogin
        ? 'Disable withdrawal'
        : 'Enable withdrawal'
      this.$confirm(textActive, this.$t('TEXT_WARNING'), {
        confirmButtonText: this.$t('BTN_OK'),
        cancelButtonText: this.$t('BTN_CANCEL'),
        type: "warning"
      })
      .then(() => {
        let data = {
          status: item.isAllowWithdraw,
          address: this.query.address
        };
        this.$store.dispatch("updateAllowWithdraw", data)
      })
      .catch(() => {
        this.currentUser.isAllowWithdraw = !item.isAllowWithdraw
      })
    },
    doCopy (value) {
      this.$copyText(value).then(() => {
        this.$store.dispatch('showSuccessMsg', this.$t('TEXT_COPIED', 'Copied'))
      })
    },
  }
}
</script>
