<template>
  <input class="value-change form-control form-control-sm col-sm-6" type="text"
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
</template>

<script>
export default {
  props: [ 'value' ]
}
</script>
