const state = {
  brokers: [],
  userBroker: [],
  broker: null,
  brokerConfig: [],
  brokersConfig: [],
  brokersCurrency: {
    currencies: [],
  },
  listBrokers: {},
  listCurrencies: [],
  listCoinInfo: [],
  listLimitOrder: [],
  listAllCurrencies: [],
  listSystemCurrencies: [],
  listSymbols: [],
  brokersSymbol: [],
  listSymbol: [],
  listCurrencyFilter: [],
  listFeeType: [],
  listBrokerWithdrawLevel: [],
  brokersAccount: [],
  brokersWallet: [],
  listBrokerCurrencyExchangeUsdt: []
}

const getters = {
  brokerConfig: state => state.brokerConfig,
  brokersConfig: state => state.brokersConfig,
  brokers: state => state.brokers,
  userBroker: state => state.userBroker,
  broker: state => state.broker,
  brokersCurrency: state => state.brokersCurrency,
  listBrokers: state => state.listBrokers,
  listCurrencies: state => state.listCurrencies,
  listCoinInfo: state => state.listCoinInfo,
  listLimitOrder: state => state.listLimitOrder,
  listAllCurrencies: state => state.listAllCurrencies,
  listSystemCurrencies: state => state.listSystemCurrencies,
  listSymbols: state => state.listSymbols,
  brokersSymbol: state => state.brokersSymbol,
  listSymbol: state => state.listSymbol,
  listCurrencyFilter: state => state.listCurrencyFilter,
  listFeeType: state => state.listFeeType,
  listBrokerWithdrawLevel: state => state.listBrokerWithdrawLevel,
  brokersAccount: state => state.brokersAccount,
  brokersWallet: state => state.brokersWallet,
  listBrokerCurrencyExchangeUsdt: state => state.listBrokerCurrencyExchangeUsdt
}

const mutations = {
  SET_BROKERS: (state, data) => {
    state.brokers = data
  },
  SET_BROKER_CONFIG: (state, data) => {
    state.brokerConfig = data
  },
  SET_BROKERS_CONFIG: (state, data) => {
    state.brokersConfig = data
  },
  SET_USER_BROKER: (state, data) => {
    state.userBroker = data
  },
  SET_BROKER_ID: (state, data) => {
    state.broker = data
  },
  SET_BROKER_CURRENCY: (state, data) => {
    state.brokersCurrency = data
  },
  SET_LIST_CURRENCY: (state, data) => {
    state.listCurrencies = data
  },
  SET_LIST_COIN_INFO: (state, data) => {
    state.listCoinInfo = data
  },
  SET_LIST_LIMIT_ORDER: (state, data) => {
    state.listLimitOrder = data
  },
  SET_LIST_BROKERS: (state, data) => {
    state.listBrokers = data
  },
  SET_BROKERS_SYMBOL: (state, data) => {
    state.brokersSymbol = data
  },
  SET_LIST_SYMBOL: (state, data) => {
    state.listSymbol = data
  },
  SET_LIST_CURRENCY_FILTER: (state, data) => {
    state.listCurrencyFilter = data
  },
  SET_LIST_ALL_CURRENCY: (state, data) => {
    console.log(data)
    state.listAllCurrencies = data
  },
  SET_LIST_SYSTEM_CURRENCY: (state, data) => {
    state.listSystemCurrencies = data
  },
  SET_LIST_SYMBOLS: (state, data) => {
    state.listSymbols = data
  },
  SET_LIST_FEE_TYPE: (state, data) =>{
    state.listFeeType = data
  },
  SET_LIST_BROKER_WITHDRAW_LEVEL:(state, data) => {
    state.listBrokerWithdrawLevel = data
  },
  SET_BROKERS_ACCOUNT: (state, data) => {
    state.brokersAccount = data
  },
  SET_BROKERS_WALLET: (state, data) => {
    state.brokersWallet = data
  },
  SET_BROKER_CURRENCY_EXCHANGE_USDT: (state, data) => {
    state.listBrokerCurrencyExchangeUsdt = data
  }
}

export default { state, getters, mutations }
