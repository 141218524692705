import Vue from 'vue'
import Constant from '@/config/Constant'
import _ from 'lodash'
import LocalStorage from './LocalStorage'
import BigNumber from 'bignumber.js'
import routingsConfig from '@/config/Routing'
import dashboardConfig from '@/config/Dashboard'
import countries from '@/assets/countries.json'

export default new Vue({
  methods: {
    numberWithCommas (x) {
      if (x === undefined) return
      var parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    },
    beautyAmount (x, decimal) {
      x = Number(x)
      const temp = (decimal || decimal === 0) ? x.toFixed(decimal) : x.toFixed(8)
      return this.numberWithCommas(temp)
    },
    transformBalancesOverview (data) {
      data.totalUsdtBalance = this.beautyAmount(data.totalUsdtBalance)
      data.totalUsdtDeposit = this.beautyAmount(data.totalUsdtDeposit)
      data.totalUsdtWithdraw = this.beautyAmount(data.totalUsdtWithdraw)
      data.totalAleoWithdraw = this.beautyAmount(data.totalAleoWithdraw)
      data.totalIronWithdraw = this.beautyAmount(data.totalIronWithdraw)
      data.totalInvest = this.beautyAmount(data.totalInvest)
      data.comRefUsd = this.beautyAmount(data.comRefUsd)
      data.comBrandUsd = this.beautyAmount(data.comBrandUsd)
      data.comRefAleo = this.beautyAmount(data.comRefAleo)
      data.comBrandAleo = this.beautyAmount(data.comBrandAleo)
      data.totalIronBonus = this.beautyAmount(data.totalIronBonus)
      data.totalIronPay = this.beautyAmount(data.totalIronPay)
      data.totalPpst = this.beautyAmount(data.totalPpst)
      return data
    },
    transformUserInfo (data) {
      if (!data || !data.length) return []
      data.forEach(element => {
        element.brandAleo = this.beautyAmount(element.brandAleo, 2)
        element.brandUsd = this.beautyAmount(element.brandUsd, 2)
        element.totalInvest = this.beautyAmount(element.totalInvest, 2)
        element.totalIronBonus = this.beautyAmount(element.totalIronBonus, 2)
        element.totalPpst = this.beautyAmount(element.totalPpst, 2)
        element.totalAleoWithdraw = this.beautyAmount(element.totalAleoWithdraw, 2)
        element.totalIronWithdraw = this.beautyAmount(element.totalIronWithdraw, 2)
        element.totalUsdtDeposit = this.beautyAmount(element.totalUsdtDeposit, 2)
        element.totalUsdtWithdraw = this.beautyAmount(element.totalUsdtWithdraw, 2)
        element.balanceAleo = this.beautyAmount(element.balanceAleo, 2)
        element.balanceIron = this.beautyAmount(element.balanceIron, 2)
        element.balanceUsdt = this.beautyAmount(element.balanceUsdt, 2)
      })
      return data
    },
    numberWithComas(x) {
      if (x === undefined) return
      var parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    },
    // Transformer
    transformKyc (data) {
      data = this.transformTimes(data)
      for (let i = 0; i < data.length; i ++) {
        data[i].kycDob && (data[i].kycDobTxt = this.$moment(data[i].kycDob).utc().local().format('YYYY-MM-DD'))
        data[i].kycTimeSubmitted && (data[i].kycTimeSubmittedTxt = this.$moment(data[i].kycTimeSubmitted).utc().local().format('YYYY-MM-DD HH:mm:ss'))
        data[i].kycTimeRejected && (data[i].kycTimeRejectedTxt = this.$moment(data[i].kycTimeRejected).utc().local().format('YYYY-MM-DD HH:mm:ss'))
        data[i].kycTimeApproved && (data[i].kycTimeApprovedTxt = this.$moment(data[i].kycTimeApproved).utc().local().format('YYYY-MM-DD HH:mm:ss'))
        data[i].kycNationalId && (data[i].countryTxt = countries.filter(temp => temp.alpha_3_code === data[i].kycNationalId)[0].en_short_name)
      }
      return data
    },
    transformHistory (data) {
      if (!data || !data.length) return []
      data.forEach(element => {
        if (element['multiplier'] && element['receive-amount']) {
          element.receiveAmount = new BigNumber(element['multiplier']).multipliedBy(element['receive-amount'])
        }
        element.fromAddress = element['fromAddress']
        element.toAddress = element['toAddress']
        switch (element.type) {
          case 'swap':
            element.currency = element.currency + '(' + element['from-currency'] + ' -> ' + element['to-currency'] + ')'
            element.amount = new BigNumber(element['multiplier']).multipliedBy(element['amount'])
            element.receiveAmount = element['to-amount']
            break
          default:
            break
        }
      })
      return data
    },
    transformOrders (data) {
      if (!data || !data.length) return []
      data.forEach(element => {
        if ((element.amount !== null && element.amount !== undefined) && (element.filledAmount !== null && element.filledAmount !== undefined)) {
          element.unexcutedAmount = new BigNumber(element.amount).minus(element.filledAmount).toNumber()
        }
        if (element.type && element.type.indexOf('market') !== -1) {
          element.price = 'TEXT_MARKET_PRICE'
          element.isMarketPrice = true
        }
        if (element.type && element.type === 'buy_market') {
          element.unexcutedAmount = 0
        }
        element.color = element.type && (element.type.indexOf('buy') !== -1) ? 'text-success' : 'text-danger'
        if (element.createdAt) element.beautyCreatedAt = this.$moment(element.createdAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
        if (element.finishedAt) element.beautyFinishedAt = this.$moment(element.finishedAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
        if (element.canceledAt) element.beautyCanceledAt = this.$moment(element.canceledAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      })
      return data
    },
    transformTimes (data) {
      if (!data || !data.length) return []
      data.forEach(element => {
        element = this.transformTime(element)
      })
      return data
    },
    multipliedBy (a, b) {
      try {
        return new BigNumber(a).multipliedBy(b).toFixed()
      } catch (error) {
        return 0
      }
    },
    transformTime (element) {
      if (element.hash) element.beautyHash = element.hash.substring(0, 18) + '...' + element.hash.substring(element.hash.length - 16, element.hash.length)
      if (element.time) element.beautyTime = this.$moment(element.time).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.createdAt) element.beautyCreatedAt = this.$moment(element.createdAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element['created-at']) element.beautyCreatedAt = this.$moment(element['created-at']).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.finishedAt) element.beautyFinishedAt = this.$moment(element.finishedAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.canceledAt) element.beautyCanceledAt = this.$moment(element.canceledAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.percent) element.beautyPercent = this.multipliedBy(element.percent, 100)
      if (element.updatedAt) element.beautyUpdatedAt = this.$moment(element.updatedAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element['updated-at']) element.beautyUpdatedAt = this.$moment(element['updated-at']).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.confirmedAt) element.beautyConfirmedAt = this.$moment(element.confirmedAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.paidAt) element.beautyPaidAt = this.$moment(element.paidAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.paymentWindowExpTime) element.beautyPaymentWindowExpTime = this.$moment(element.paymentWindowExpTime).utc().local().format('YYYY-MM-DD HH:mm:ss')
      return element
    },
    transformTimeat (element) {
      if (element.createdAt) element.createdAt = this.$moment(element.createdAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.finishedAt) element.finishedAt = this.$moment(element.finishedAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.canceledAt) element.canceledAt = this.$moment(element.canceledAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.updatedAt) element.updatedAt = this.$moment(element.updatedAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.registerAt) element.registerAt = this.$moment(element.registerAt).utc().local().format('YYYY-MM-DD HH:mm:ss')
      if (element.endTimeLockWithdraw) element.endTimeLockWithdraw = this.$moment(element.endTimeLockWithdraw).utc().local().format('YYYY-MM-DD HH:mm:ss')
      return element
    },

    // Check valid data utils
    checkValidDecimals (value, precision) {
      value = Number(value)
      if (Math.floor(value) === value) {
        return true
      }
      try {
        let arr = value.toString().split('.')
        return arr[1].length <= precision || false
      } catch (error) {
        return false
      }
    },
    checkValidateEmail (email) {
      var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return re.test(email)
    },
    checkRole (user, route) {
      if (!route.meta || !route.meta.requiresAuth || !route.meta.roles || !route.meta.roles.length)
        return true
      return this.isUserHasRole(user, route.meta.roles)
    },
    isUserHasRole (user, roles) {
      if (!user || !user.roles) return true
      let userRoles = user.roles.map(temp => {
        return temp.role
      })
      const isRoleInRoute = userRoles.some(role => roles.indexOf(role) >= 0)
      return isRoleInRoute
    },
    // Check valid route
    isShowRouter (user, routeName) {
      if (!routeName || routeName.toLowerCase() === 'dashboard' || routeName.toLowerCase() === 'login') return true
      let routingConfigs = routingsConfig
      if (!user || !user.permission) return true
      let userPermissionRoutes = routingConfigs[user.permission.toLowerCase()]
      if (!userPermissionRoutes || !userPermissionRoutes.length) return true
      userPermissionRoutes = userPermissionRoutes.map(temp => temp.toLowerCase())
      if (userPermissionRoutes.indexOf(routeName.toLowerCase()) !== -1) return true
      return false
    },
    // Show dashboard block
    isShowDashboardBlock (user, blockId) {
      if (!user || !user.permission) return true
      let userDashboardBlock = dashboardConfig[user.permission.toLowerCase()]
      if (!userDashboardBlock || !userDashboardBlock.length) return true
      return userDashboardBlock.indexOf(blockId.toLowerCase()) !== -1
    },

    // Generator utils
    generateNumberFormat (precision) {
      let temp = '0000000000000000000000000000000'
      let result = '0.'
      let target = temp.substr(0, precision)
      result += target
      return result
    },
    generateRandomString (length) {
      var text = ''
      var possible = '_ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-'
      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
      }
      return text
    },

    // Cache utils
    clearCacheLocalStorage () {
      LocalStorage.removeItem(Constant.EX_LIST_PAIRS)
      LocalStorage.removeItem(Constant.SYSTEM_CONFIG)
    },

    // Map utils
    transformUserOverview (data) {
      if (!data) return {}
      let userOverview = {
        count: [],
        label: []
      }
      let times = Object.keys(data)
      userOverview.times = times
      let keys = Object.keys(data[times[0]])
      let series = []
      let keyMap = {
        kycPending: 'KYC Pending',
        kyc: 'Approve KYC',
        active: 'Active',
        inActive: 'Inactive',
        lock: 'Lock',
        total: 'Total'
      }
      keys.forEach(key => {
        let obj = {
          name: keyMap[key],
          data: []
        }
        times.forEach(time => {
          obj.data.push(data[time][key])
        })
        series.push(obj)
      })
      series.forEach(element => {
        if (element.name !== 'Total') {
          let totalData = element.data.reduce((a, b) => a + b, 0)
          userOverview.count.push(totalData)
        }
      })
      userOverview.series = series
      // times = times.map(temp => {
      //   return this.$moment.unix(temp/1000).format('YYYY-MM-DD')
      // })
      userOverview.categories = times
      userOverview.labels = keys.filter(key => {
        return key !== 'total'
      }).map(key => {
        return keyMap[key]
      })
      return userOverview
    },
    encodeURIComponentParams (params) {
      if (params) {
        let keys = Object.keys(params)
        keys.forEach(key => {
          params[key] = encodeURIComponent(params[key])
        })
      }
      return params
    }
  }
})
