export default {
  // Response Status
  SUCCESS: 'Success',
  ERROR: 'Error',

  // Roles
  ROLE: {
    BROKER: 'ADMIN',
    CENTRAL: 'ADMIN_CENTRAL'
  },

  // Error code
  UNAUTHORIZED_CODE: 403,
  INVALID_TOKEN: 401,
  INVALID_ROLE: 409,

  // LocalStorage
  JWT: 'jwt',
  IS_LOGGED: 'Auth:IsLogged',
  COLORS_LEVEL: {
    USER: '#666060',
    STARTER: '#A9A8A9',
    R1: '#898989',
    R2: '#408ffc',
    R3: '#005830',
    R4: '#6E47CE',
    R5: '#E4A11E',
    R6: '#F77F15',
    R7: '#CD3C7F',
    R8: '#A51E1B',
    R0: '#A9A8A9'
  },
  BASE_COLOR: '#071435',
  // Kyc status
  KYC_STATUS: {
    NONE: 0,
    PENDING: 1,
    REJECTED: -1,
    VERIFIED: 2
  },
  LANGUAGE_LINK: 'https://s3.ap-southeast-1.amazonaws.com/language.romaniex.com/'
}