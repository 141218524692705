import LocalStorage from '@/utils/LocalStorage'
import CONSTANT from '@/config/Constant'
import Router from '@/router/index'
import ConfigService from '@/api/common/ConfigService'
import UtilService from '../../../utils/UtilService'
const actions = {
  async getRateHistoryConfigs (ctx, data) {
    const responseData = await ConfigService.getRateAleoHistory(data)
    ctx.dispatch('setPagination', responseData.pagination)
    let result = UtilService.transformTimes(responseData.data || [])
    ctx.dispatch('setRateConfigs',  result)
    return responseData.data
  },
  async getRateAleo (ctx, data) {
    const responseData = await ConfigService.getRateAleo(data)
    ctx.dispatch('setRateAleo', responseData.data)
    return responseData.data
  },
  async addConfig(_, data) {
    let res = await ConfigService.updateRateAleo(data)
    return res
  },
  showSuccessMsg (__, message) {
    this._vm.$notify.success({ message: message, duration: 1800 })
  },
  showErrorMsg (__, message) {
    this._vm.$notify.error({ message: message, duration: 1800 })
  },
  checkLoggedIn (__) {
    return new Promise(resolve => {
      var token = LocalStorage.getToken() || ''
      var isLoggedIn = LocalStorage.getItem(CONSTANT.IS_LOGGED) || false

      (token && token.token && isLoggedIn)
        ? resolve(true)
        : (Router.push({ name: 'Login' }), resolve(false))
    })
  }
}

export default { actions }
