<template>
  <div class="main-card mb-3 card" :class="{'load-bar' : isSearching}">
    <div class="card-body">
      <h5 class="card-title">
        {{$t('TEXT_TRANSACTION_BUY_HISTORY')}}
      </h5>
      <div class="search-wrap large">
        <div class="search-item">
          <label>Symbol</label>
          <el-select v-model="query.symbol" @change="search" placeholder="All symbol" clearable>
            <el-option label="ALEO" value="ALEO" key="aleo" class="text-capitalize"></el-option>
            <el-option label="KAS" value="KAS" key="kas" class="text-capitalize"></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <label for="search-address-from">Email / Invite Code / Username</label>
          <el-input
              id="search-address-from"
              type="text"
              v-model="query.email"
              @keyup.enter="search"
              @change="search"
              clearable
          >
          </el-input>
        </div>
        <div class="search-item d-flex">
          <button class="btn btn-primary" @click.prevent="search">{{$t('TEXT_SEARCH')}}</button>
        </div>
      </div>
      <div class="classic-table large" sticky-container>
        <dl>
          <dt  v-sticky sticky-offset="{top: 60}">
            <div class="w-60 ">No.</div>
            <div class="flex-3">Email</div>
            <div class="flex-1">Token Mining</div>
            <div class="flex-1">USD Amount</div>
            <div class="flex-1">Price</div>
            <div class="flex-1">Power Minting</div>
            <div class="flex-1">Token Bonus</div>
            <div class="flex-1">P.Minting Bonus</div>
            <div class="flex-2">Date</div>
          </dt>
          <template v-if="transactions.length">
            <dd v-for="(item, index) in transactions" :key="item.id">
              <div class="w-60">{{index+1}}</div>
              <div class="flex-3">{{item.email}}
                <i class="el-icon-copy-document" style="font-size: 14px; cursor: pointer;" @click="doCopy(item.email)"></i>
              </div>
              <div class="flex-1">
                {{item.tokenMining}}
                <el-tooltip content="Convert from PPST" placement="right-start" v-if="item.packConvert">
                  <img src="/assets/images/icon-bolt.svg" alt="Icon" style="width: 20px; padding: 5px;">
                </el-tooltip>
              </div>
              <div class="flex-1">{{item.amountUsdBeauty}}</div>
              <div class="flex-1">{{item.price}}</div>
              <div class="flex-1">{{item.amountPowerBeauty}}</div>
              <div class="flex-1">{{item.tokenBonusBeauty}}</div>
              <div class="flex-1">{{item.powerBonusBeauty}}</div>
              <div class="flex-2">{{item.beautyCreatedAt}}</div>
            </dd>
          </template>
        </dl>
        <el-pagination
            v-if="transactions.length"
            layout="total, prev, pager, next, jumper"
            :page-count="pagination.total_pages"
            :page-size="pagination.size"
            :current-page="pagination.page"
            :total="pagination.total_records"
            @current-change="goToPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import FilterData from '@/mixin/FilterData'
import NoData from '@/views/components/NoData.vue'
import Sticky from 'vue-sticky-directive'

export default {
  mixins: [ FilterData ],
  components: {
    NoData
  },
  directives: {Sticky},
  data () {
    return {
      isSearching: false
    }
  },
  computed: {
    transactions () {
      return this.$store.getters.buyHistoryTransaction
    }
  },
  created () {
    this.query = JSON.parse(JSON.stringify(this.$route.query));
    this.fetch()
  },
  methods: {
    fetch () {
      let filterObj = this.buildQueryObj()
      this.isSearching = true
      this.$store.dispatch('getBuyHistoryTransaction', filterObj).then(() => {
        this.isSearching = false
      })
    },
    doCopy (text) {
      this.$copyText(text).then(e => {
        this.$store.dispatch('showSuccessMsg', this.$t('TEXT_COPIED', 'Copied'))
      })
    }
  }
}
</script>
