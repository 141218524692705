<template>
  <div class="main-card mb-3 card" :class="{'load-bar' : isSearching || isLoading}">
    <div class="card-body">
      <h5 class="card-title">
        {{$t('TEXT_TRANSACTION_HISTORY')}}
      </h5>
      <div class="search-wrap large">
        <div class="search-item">
          <label for="search-address-from">Invite code</label>
          <input
            id="search-address-from"
            type="text"
            v-model="query.inviteCode"
            @keyup.enter="search"
          />
        </div>
        <!-- <div class="search-item">
          <label for="search-hash">{{$t('TEXT_HASH')}}</label>
          <input
            id="search-hash"
            type="text"
            v-model="query.hash"
            @keyup.enter="search"
          />
        </div> -->
        <div class="search-item">
          <label>{{$t('TEXT_TYPE')}}</label>
          <el-select v-model="query.type" @change="search" placeholder="Type">
            <el-option
              v-for="(type, index) in transactionsType"
              :key="index"
              :label="$t(type)"
              :value="$t(type)"
              class="text-capitalize"
            ></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <label>{{$t('TEXT_STATUS')}}</label>
          <el-select v-model="query.status" @change="search" placeholder="Status">
            <el-option
              v-for="(status, index) in transactionsStatus"
              :key="index"
              :label="$t(status)"
              :value="$t(status)"
              class="text-capitalize"
            ></el-option>
          </el-select>
        </div>
        <!-- <div class="search-item">
         <label>{{$t('TEXT_CURRENCY')}}</label>
          <el-select v-model="query.currency" @change="search" multiple placeholder="Currency">
            <el-option
              v-for="(item, index) in listCurrencies"
              :key="index"
              :label="item.symbol"
              :value="item.symbol"
              class="text-capitalize">
            </el-option>
          </el-select>
        </div>  -->
        <div class="search-item d-flex">
          <button class="btn btn-primary" @click.prevent="search">{{$t('TEXT_SEARCH')}}</button>
        </div>
        <div class="search-item d-flex full-width double">
          <button class="btn btn-primary" @click.prevent="showScanByHash">Scan</button>
        </div>
      </div>
      <div class="classic-table large" sticky-container>
        <dl>
          <dt v-sticky sticky-offset="{top: 60}">
            <div class="w-30 action">&nbsp;</div>
            <div class="w-130">Address</div>
            <div class="w-130">To Address</div>
            <div class="w-80">Memo</div>
            <div class="w-240">Email</div>
            <div class="w-80">{{$t('TEXT_CURRENCY')}}</div>
            <div class="w-80">Network</div>
            <div class="w-110">{{$t('TEXT_TYPE')}}</div>
            <div class="w-110">{{$t('TEXT_STATUS')}}</div>
            <div class="">{{$t('TEXT_HASH')}}</div>
            <div class="w-90 text-right">{{$t('TEXT_AMOUNT')}}</div>
            <!-- <div class="w-110 text-right" :title="$t('TEXT_RECEIVE_AMOUNT')">{{$t('TEXT_RECEIVE_AMOUNT')}}</div> -->
            <div class="date">{{$t('TEXT_CREATED_AT')}}</div>
          </dt>
          <template v-if="transactions.length">
            <dd v-for="item in transactions" :key="item.id">
              <div class="w-30 action">
                <i class="pe-7s-menu"></i>
                <div class="sub-menu" v-if="(['REVIEW', 'ERROR', 'APPROVED'].includes(item.status)) && item.type === 'WITHDRAW'">
                  <a href="javascript:;" @click.prevent="confirm(item)">{{$t('TEXT_DETAIL')}}</a>
                </div>
              </div>
              <div class="w-130" @click="copy(item.fromAddress)">{{beautyAddress(item.fromAddress) }}</div>
              <div class="w-130" @click="copy(item.toAddress)">{{beautyAddress(item.toAddress) }}</div>
              <div class="w-80">{{item.memo }}</div>
              <div class="w-240" @click="copy(item.email)">{{item.email }}</div>
              <div class="w-80">{{item.currencyCode}}</div>
              <div class="w-80">{{item.network}}</div>
              <div class="w-110">
                <el-tag v-if="(item.type == 'deposit')" type="success"
                >{{item.type}}</el-tag>
                <el-tag v-else type="danger"
                >{{item.type}}</el-tag>
              </div>
              <div class="w-110">
                <el-tag v-if="(item.status == 'Completed')" type="success"
                >{{item.status}}</el-tag>
                <el-tag v-else-if="(item.status == 'Reject')" type="warning"
                >{{item.status}}</el-tag>
                <el-tag v-else-if="(item.status == 'Sending')" type="success"
                >{{item.status}}</el-tag>
                <el-tag v-else-if="(item.status == 'Approved')" type="danger"
                >{{item.status}}</el-tag>
                <el-tag v-else-if="(item.status == 'Error')" type="danger "
                >{{item.status}}</el-tag>
                <el-tag v-else-if="(item.status == 'Review')"
                >{{item.status}}</el-tag>
                <el-tag v-else-if="(item.status == 'Pending')" type="warning"
                >{{item.status}}</el-tag>
                <el-tag v-else type="warning"
                >{{item.status}}</el-tag>
              </div>
              <div class="" @click="copy(item.hash)">{{ beautyAddress(item.hash) }}</div>
              <div class="w-90 text-primary text-right">{{ formatNumber(item.amount) }}</div>
              <!-- <div class="w-110 text-primary text-right">{{ item.receiveAmount }}</div> -->
              <div class="date">{{ item.beautyCreatedAt }}</div>
              <!-- <div class="date">
                <span>{{ item.beautyUpdatedAt }}</span>
              </div> -->
            </dd>
          </template>
          <NoData v-else />
        </dl>
        <el-pagination
          v-if="transactions.length"
          layout="total, prev, pager, next"
          :page-count="pagination.total_pages"
          :page-size="pagination.size"
          :current-page="pagination.page"
          :total="pagination.total_records"
          @current-change="goToPage">
        </el-pagination>
      </div>
    </div>
     <el-dialog
      title="Confirm withdraw"
      :visible.sync="dialogVisibleCreat"
      :before-close="handleClose"
      :closeOnClickModal="false"
      :destroy-on-close="true"
      class="medium"
    >
      <div>
        <form class="mx-auto from-update-broker" id="creatBrokerConfig" method="post" action="">
          <div class="">
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                ID(*)
              </label>
              <!-- <InputNumber v-model="form.makerFee"/> -->
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                    v-model="form.id">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Hash(*)
              </label>
              <!-- <InputNumber v-model="form.takerFee"/> -->
              <input class="value-change form-control form-control-sm col-sm-6" type="text" :disabled="form.status !== 'COMPLETED'"
                    v-model="form.hash">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Status(*)
              </label>
              <el-select v-model="form.status" @change="search" placeholder="Type">
                <el-option
                  v-for="(status, index) in transactionConfirmStatus"
                  :key="index"
                  :label="$t(status)"
                  :value="$t(status)"
                  class="text-capitalize"
                ></el-option>
              </el-select>
              <!-- <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                    v-model="form.status"> -->
            </div>
          </div>
          <div class="from-group text-center out-btn-update">
              <button :disabled="isProcessing || !isValidatedForm" @click.prevent="confirmWithdraw()" class="btn btn-primary">{{$t('BTN_CONFIRM')}}</button>
          </div>
        </form>
      </div>
    </el-dialog>

    <el-dialog
      title="Scan by hash"
      :visible.sync="dialogVisibleScanHash"
      :before-close="handleClose"
      :closeOnClickModal="false"
      :destroy-on-close="true"
      class="medium"
    >
      <div>
        <form class="mx-auto from-update-broker" id="creatBrokerConfig" method="post" action="">
          <div class="">
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Hash(*)
              </label>
              <!-- <InputNumber v-model="form.takerFee"/> -->
              <input class="value-change form-control form-control-sm col-sm-6" type="text"
                    v-model="formScan.hash">
            </div>
          </div>
          <div class="from-group text-center out-btn-update">
              <button :disabled="!formScan.hash" @click.prevent="getTransactionByHash()" class="btn btn-primary">{{$t('BTN_CONFIRM')}}</button>
          </div>
        </form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import FilterData from '@/mixin/FilterData';
import NoData from '../components/NoData'
import Sticky from 'vue-sticky-directive'
export default {
  mixins: [ FilterData ],
  components: {
    NoData
  },
  directives: {Sticky},
  data () {
    return {
      dialogVisibleScanHash: false,
      dialogVisibleCreat: false,
      isSearching: false,
      isProcessing: false,
      currentTran: {},
      isLoading: false,
      formScan: {
        hash: ''
      },
      form: {
        id: null,
        hash: '',
        status: ''
      }
    }
  },
  computed: {
    isValidatedForm () {
      return this.isValidForm || this.isValidFormWithStatus
    },
    isValidForm () {
      return this.form.id && this.form.status && (this.form.status !== 'COMPLETED')
    },
    isValidFormWithStatus () {
      return this.form.id && (this.form.status === 'COMPLETED') && this.form.hash
    },
    transactions () {
      return this.$store.getters.transactions
    },
    listCurrencies () {
      this.$store.getters.listCurrencies.unshift({symbol: 'All'})
      return this.$store.getters.listCurrencies
    },
    transactionsStatus () {
      this.$store.getters.transactionsStatus.unshift('All')
      return this.$store.getters.transactionsStatus
    },
    transactionsType () {
      this.$store.getters.transactionsType.unshift('All')
      return this.$store.getters.transactionsType
    },
    broker() {
      return this.$store.getters.broker;
    },
    transactionConfirmStatus () {
      return this.$store.getters.transactionConfirmStatus
    }
  },
  watch: {
    'form.status' (newVal, oldVal) {
      if (newVal !== 'COMPLETED') {
        this.form.hash = this.currentTran.hash
      }
    }
  },
  created() {
    this.query = JSON.parse(JSON.stringify(this.$route.query));
    if (!this.query.inviteCode) this.query.inviteCode = 0
    if (!this.query.status) this.query.status = 'REVIEW'
    if (!this.query.type) this.query.type = 'WITHDRAW'
    // this.$store.dispatch('getListCurrency').then(() => {
    // })
    this.$store.dispatch('getTransactionsStatus').then(() => {
    })
    this.$store.dispatch('getTransactionsType').then(() => {
    })
    this.$store.dispatch('getTransactionConfirmStatus')
    this.fetch();
  },
  methods: {
    getTransactionByHash () {
      if (!this.formScan.hash) return
      this.isLoading = true
      this.$store.dispatch('getTransactionByHash', this.formScan.hash).then(status => {
        this.isLoading = false
        if (status) {
          this.dialogVisibleScanHash = false
          this.formScan.hash = ''
          this.fetch()
        }
      }).catch(e => {
        this.isLoading = false
      })
    },
    showScanByHash () {
      this.dialogVisibleScanHash = true
    },
    confirmWithdraw () {
      this.$store.dispatch('confirmTransactionWithdraw', this.form).then(rs => {
        if (rs.status) {
          this.fetch()
          this.dialogVisibleCreat = false
        }
      })
    },
    handleClose (done) {
        this.dialogVisibleCreat = false
        this.form = {}
        done && done()
      },
    confirm (item) {
      this.currentTran = item
      this.form.id = item.id
      this.form.hash = item.hash
      this.form.status = this.transactionConfirmStatus[0]
      this.dialogVisibleCreat = true
      this.isLoading = false
    },
    fetch () {
      let filterObj = this.buildQueryObj()
      if (this.query.status) {
        filterObj.status = this.query.status
      }

      if (this.query.inviteCode !== null && this.query.inviteCode !== undefined) {
        filterObj.inviteCode = this.query.inviteCode
      }

      if (this.query.type) {
        filterObj.type = this.query.type
      }

      this.isSearching = true
      this.$store.dispatch('getTransactions', filterObj).then(() => {
        this.isSearching = false
      })

      // Delete currency
      if (filterObj.currency === 'All') {
        delete filterObj.currency
      }
      // Delete status
      if (filterObj.status === 'All') {
        delete filterObj.status
      }
      // Delete type
      if (filterObj.type === 'All') {
        delete filterObj.type
      }
    },
    formatNumber(value) {
      let newNumber = value.toString().split(".")
      newNumber[0] = newNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return newNumber.join(".")
    },
    copy (item) {
      this.$copyText(item).then(e => {
        this.$store.dispatch('showSuccessMsg', this.$t('TEXT_COPIED', 'Copied'))
      })
    },
    exportExcelTransactions () {
      this.exportExcel('transaction')
    },
    beautyAddress (address) {
      if (!address) {
        return ''
      }
      return address.substring(0, 6) + '...' + address.substring(address.length - 10, address.length)
    }
  }
}
</script>
