<template>
  <div class="app-header header-shadow">
    <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar"
                  :class="{ 'is-active': !isOpenedSidebar }"
                  @click="toggleSidebar">
                    <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <div>
            <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav" @click="toggleSidebar"
              :class="{ 'is-active': isOpenedMBSidebar }">
                <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-header__menu">
        <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
                  @click="toggleHeader" :class="{ 'active': isOpenMBHeader }">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
        </button>
        </span>
    </div>
    <div class="app-header__content"
      :class="{ 'header-mobile-open': isOpenMBHeader }">
        <div class="app-header-right">
          <!-- <div class="header-dots">
            <Notification/>
            <Languages/>
          </div> -->
          <ProfileMenu/>
        </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import ProfileMenu from './header/ProfileMenu'
import Languages from './header/Languages'
import Notification from './header/Notification'
export default {
  data () {
    return {
      isOpenSearch: false
    }
  },
  components: {
    ProfileMenu,
    Languages,
    Notification
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    isOpenedSidebar () {
      return this.$store.getters.isOpenedSidebar
    },
    isOpenedMBSidebar () {
      return this.$store.getters.isOpenedMBSidebar
    },
    isOpenMBHeader () {
      return this.$store.getters.isOpenMBHeader
    },
    disabledBroker () {
      if(this.$route.name === 'UserActivity'|| this.$route.name === 'UserDetail')
        return true
      else 
        return false
    }
  },
  methods: {
    toggleSidebar () {
      this.$store.dispatch('toggleSideBar')
    },
    openSearch (open) {
      this.isOpenSearch = open
    },
    toggleHeader () {
      this.$store.dispatch('toggleMBHeader')
    }
  }
}
</script>
