import CONFIG from '@/config/Config'
import { Get } from '@/utils/RequestService'
var apiUrl = CONFIG.API_POINT
var service = {
  getOrders: (params) => Get(`${apiUrl}/order/list-order`, params),
  getOrderOverview: (params) => Get(`${apiUrl}/order/statical-order`, params),
  getOrderDetail: (params) => Get(`${apiUrl}/order/detail-order/`, params),
  getOrderType: () => Get(`${apiUrl}/public/list-order-type`)
}
export default service
