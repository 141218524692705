<template>
  <div class="wrapper-dashboard" :class="adminRole">

    <!--  Commission Overview  -->
    <div class="main-card mb-3 card" id="commission-overview" v-if="balanceOverView.commissionOverviews">
      <div class="card-body">
        <div class="card-title card-header-title font-size-lg text-capitalize font-weight-normal">Commission Overview</div>
        <div class="table-responsive">
          <table class="align-middle text-truncate mb-0 table table-bordered table-hover">
            <thead>
            <tr class="text-center">
              <th>Currency</th>
              <th>Commission Brand</th>
              <th>Commission Ref</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center" v-for="(currency, index) in balanceOverView.commissionOverviews" :key="index">
              <td>{{currency.currency}}</td>
              <td>{{numberWithCommas(currency.commissionBrand)}}</td>
              <td>{{numberWithCommas(currency.commissionRef)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--  End Commission Overview  -->

    <!--  Invest Overview  -->
    <div class="main-card mb-3 card" id="invest-overview" v-if="balanceOverView.investOverviews">
      <div class="card-body">
        <div class="card-title card-header-title font-size-lg text-capitalize font-weight-normal">Invest Overview</div>
        <div class="table-responsive">
          <table class="align-middle text-truncate mb-0 table table-bordered table-hover">
            <thead>
            <tr class="text-center">
              <th>Currency</th>
              <th>Total invest (USDT)</th>
              <th>Total mining</th>
              <th>Total Power</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center" v-for="(currency, index) in balanceOverView.investOverviews" :key="index">
              <td>{{currency.currency}}</td>
              <td>{{numberWithCommas(currency.totalInvestUsdt)}}</td>
              <td>{{numberWithCommas(currency.totalMining)}}</td>
              <td>{{numberWithCommas(currency.totalPower)}} {{currency.powerType}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--  End Invest Overview  -->

    <!--  Bonus Overview  -->
    <div class="main-card mb-3 card" id="bonus-overview" v-if="balanceOverView.bonusOverviews">
      <div class="card-body">
        <div class="card-title card-header-title font-size-lg text-capitalize font-weight-normal">Bonus Overview</div>
        <div class="table-responsive">
          <table class="align-middle text-truncate mb-0 table table-bordered table-hover">
            <thead>
            <tr class="text-center">
              <th>Currency</th>
              <th>Total Bonus</th>
              <th>Total Pay</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center" v-for="(currency, index) in balanceOverView.bonusOverviews" :key="index">
              <td>{{currency.currency}}</td>
              <td>{{numberWithCommas(currency.totalBonus)}}</td>
              <td>{{numberWithCommas(currency.totalPay)}}</td>
            </tr>
            </tbody>
          </table>
      </div>
      </div>
    </div>
    <!--  End Bonus Overview  -->

    <!--  Balance Overview  -->
    <div class="main-card mb-3 card" id="balance-overview" v-if="balanceOverView.balanceOverviews">
      <div class="card-body">
        <div class="card-title card-header-title font-size-lg text-capitalize font-weight-normal">Balance Overview</div>
        <div class="table-responsive">
          <table class="align-middle text-truncate mb-0 table table-bordered table-hover">
            <thead>
            <tr class="text-center">
              <th>Currency</th>
              <th>Current Balance</th>
              <th>Total Deposit</th>
              <th>Total Withdraw</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center" v-for="(currency, index) in balanceOverView.balanceOverviews" :key="index">
              <td>{{currency.currency}}</td>
              <td>{{numberWithCommas(currency.currentBalance)}}</td>
              <td>{{numberWithCommas(currency.totalDeposit)}}</td>
              <td>{{numberWithCommas(currency.totalWithdraw)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div >
    <!--  End Balance Overview  -->
  </div>
</template>

<script>
import UserChart from './components/UserChart'
import UserDonutChart from './components/UserDonutChart'
import UtilService from '@/utils/UtilService'
export default {
  name: 'dashboard',
  data () {
    return {
      time: 5,
      isreFreshOrDer: false,
      isreFreshBalance: false,
      times: [
        {
          id: 1,
          name: 'TEXT_TODAY'
        },
        {
          id: 2,
          name: 'TEXT_YESTERDAY'
        },
        {
          id: 3,
          name: 'TEXT_THIS_WEEK'
        },
        {
          id: 4,
          name: 'TEXT_LAST_WEEK'
        },
        {
          id: 5,
          name: 'TEXT_THIS_MONTH'
        },
        {
          id: 6,
          name: 'TEXT_LAST_MONTH'
        }
      ]
    }
  },
  components: {
    UserChart,
    UserDonutChart
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    broker () {
      return this.$store.getters.broker
    },
    orderOverview () {
      return this.$store.getters.orderOverview
    },
    balanceOverView () {
      return this.$store.getters.balanceOverView
    },
    tokenRemaining () {
      return this.$store.getters.tokenRemaining
    },
    adminRole () {
      return this.$store.getters.adminRole
    }
  },
  watch: {
    time (n, o) {
      if (n !== o) {
        this.fetchUserOverview(n)
      }
    }
  },
  created () {
    // this.fetchUserOverview(this.time)
    this.fetch()
  },
  methods: {
    isShowBlock (blockId) {
      return UtilService.isShowDashboardBlock(this.user, blockId)
    },
    mapTimeRange (id) {
      let startCreateTime, endCreateTime, type
      switch (id) {
        case 1:
          // Get ts for today
          startCreateTime = this.$moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')
          endCreateTime  = this.$moment().format('YYYY-MM-DDTHH:mm:ss')
          type = 'h'
          console.log('today', startCreateTime, endCreateTime)
          break
        case 2:
          // Get ts for last day
          startCreateTime = this.$moment().startOf('day').subtract('days', 1).format('YYYY-MM-DDTHH:mm:ss')
          endCreateTime  = this.$moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss')
          type = 'h'
          console.log('last day', startCreateTime, endCreateTime)
          break
        case 3:
          // Get ts for this weeek
          startCreateTime = this.$moment().startOf('week').add('days', 1).format('YYYY-MM-DDTHH:mm:ss')
          endCreateTime   = this.$moment().format('YYYY-MM-DDTHH:mm:ss')
          console.log('this week', startCreateTime, endCreateTime)
          type = 'd'
          break
        case 4:
          // Get ts for last weeek
          startCreateTime = this.$moment().startOf('week').subtract('days', 6).format('YYYY-MM-DDTHH:mm:ss')
          endCreateTime   = this.$moment().startOf('week').add('days', 1).format('YYYY-MM-DDTHH:mm:ss')
          console.log('last week', startCreateTime, endCreateTime)
          type = 'd'
          break
        case 5:
          // Get ts for this month
          startCreateTime = this.$moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
          endCreateTime   = this.$moment().format('YYYY-MM-DDTHH:mm:ss')
          console.log('this month', startCreateTime, endCreateTime)
          type = 'd'
          break
        case 6:
          // Get ts for last month
          startCreateTime = this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DDTHH:mm:ss')
          endCreateTime = this.$moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
          console.log('last month', startCreateTime, endCreateTime)
          type = 'd'
          break
        default:
          startCreateTime = this.$moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
          endCreateTime   = this.$moment().format('YYYY-MM-DDTHH:mm:ss')
          type = 'd'
          console.log('default', startCreateTime, endCreateTime)
          break
      }
      return { startCreateTime, endCreateTime, type }
    },
    fetch () {
      this.isreFreshOrDer = true
      this.$store.dispatch('getBalanceOverView')
      // this.$store.dispatch('getTokenRemanining')
    },
    formatNumber(value) {
      let newNumber = value.toString().split(".")
      newNumber[0] = newNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return newNumber.join(".")
    },
    goToOrderList () {
      this.$router.push({ name: 'OrderList' })
    },
    goToUserPage () {
      this.$router.push({ name: 'User' })
    },
    goToWalletList () {
      this.$router.push({ name: 'Wallet' })
    },
    reFreshOrDer() {
      if (this.broker){
        this.isreFreshOrDer = true
        this.$store.dispatch('getOrderOverview', { broker: this.broker }).then(() => {
          this.isreFreshOrDer = false
        })
      }
    },
    reFreshBalance() {
      if (this.broker){
        this.isreFreshBalance = true
        this.$store.dispatch('getBalanceOverView', { broker: this.broker }).then(() => {
          this.isreFreshBalance = false
        })
      }
    },
    fetchUserOverview (timeId) {
      // let timeParams = this.mapTimeRange(timeId)
      // if (!this.broker) return
      // timeParams.broker = this.broker
      // this.$store.dispatch('getUserOverview', timeParams)
    },
    numberWithCommas(number) {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}
</script>
