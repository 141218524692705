import LocalStorage from '@/utils/LocalStorage'
import CONSTANT from '@/config/Constant'
import OtcService from '@/api/OtcService'
import UtilService from '../../../utils/UtilService'
var actions = {
  // OTC Fiat Page
    getOtcFiat (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getOtcFiat(params)
        let OtcFiat = res.data
        let result = UtilService.transformTimes(OtcFiat)
        ctx.dispatch('setOtcFiat', result)
        resolve(result)
      })
    },
    updateOtcFiat(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.updateOtcFiat(data)
        resolve(res)
      })
    },
    addOtcFiat(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.addOtcFiat(data)
        resolve(res)
      })
    },
    // OTC Currency Page
    getOtcCurrency (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getOtcCurrency(params)
        let OtcCurrency = res.data
        let result = UtilService.transformTimes(OtcCurrency)
        ctx.dispatch('setOtcCurrency', result)
        resolve(result)
      })
    },
    updateOtcCurrency(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.updateOtcCurrency(data)
        resolve(res)
      })
    },
    addOtcCurrency(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.addOtcCurrency(data)
        resolve(res)
      })
    },
    // OTC Country Page
    getOtcCountry (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getOtcCountry(params)
        let OtcCountry = res.data
        let result = UtilService.transformTimes(OtcCountry)
        ctx.dispatch('setOtcCountry', result)
        resolve(result)
      })
    },
    updateOtcCountry(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.updateOtcCountry(data)
        resolve(res)
      })
    },
    addOtcCountry(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.addOtcCountry(data)
        resolve(res)
      })
    },
    getListFiatByBroker (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListFiatByBroker(params)
        let ListFiatByBroker = res.data
        ctx.dispatch('setListFiatByBroker', ListFiatByBroker)
        resolve(ListFiatByBroker)
      })
    },
    // payment Method
    getListOtcPaymentMethod (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListOtcPaymentMethod(params)
        let OtcCountry = res.data
        let result = UtilService.transformTimes(OtcCountry)
        ctx.dispatch('setListOtcPaymentMethod', result)
        resolve(result)
      })
    }, 
    updateOtcPaymentMethod(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.updateOtcPaymentMethod(data)
        resolve(res)
      })
    },
    addOtcPaymentMethod(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.addOtcPaymentMethod(data)
        resolve(res)
      })
    },
    // System Config
    getListOtcSystemConfig (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListOtcSystemConfig(params)
        let OtcCountry = res.data
        let result = UtilService.transformTimes(OtcCountry)
        ctx.dispatch('setListOtcSystemConfig', result)
        resolve(result)
      })
    }, 
    updateOtcSystemConfig(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.updateOtcSystemConfig(data)
        resolve(res)
      })
    },
    addOtcSystemConfig(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.addOtcSystemConfig(data)
        resolve(res)
      })
    },
    getListBrokerFilter (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListBrokerFilter(params)
        let OtcCountry = res.data
        let result = UtilService.transformTimes(OtcCountry)
        ctx.dispatch('setListBrokerFilter', result)
        resolve(result)
      })
    }, 
    //
    getOtcOrderType (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getOtcOrderType(params)
        let otcOrderType = res.data
        ctx.dispatch('setOtcOrderType', otcOrderType)
        resolve(otcOrderType)
      })
    },
    getOtcOrderStatus (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getOtcOrderStatus(params)
        let otcOrderStatus = res.data
        ctx.dispatch('setOtcOrderStatus', otcOrderStatus)
        resolve(otcOrderStatus)
      })
    },
    getListFiatCode (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListFiatCode(params)
        let listFiatCode = res.data
        ctx.dispatch('setListFiatCode', listFiatCode)
        resolve(listFiatCode)
      })
    },
    updateOtcCurrency(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.updateOtcCurrency(data)
        resolve(res)
      })
    },
    getListOtcAdvance (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListOtcAdvance(params)
        let listAdvanceOtc = res.data
        ctx.dispatch('setPagination', res.pagination)
        let result = UtilService.transformTimes(listAdvanceOtc)
        ctx.dispatch('setListOtcAdvance', result)
      })
    },
    // List OTC Order Page
    getListOtcOrder (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListOtcOrder(params)
        let otcListOrder = res.data
        ctx.dispatch('setPagination', res.pagination)
        let result = UtilService.transformTimes(otcListOrder)
        ctx.dispatch('setOtcListOrder', result)
        resolve(result)
      })
    },
    //List OTC Users Page
    getListOtcUsers (ctx, params) {
      return new Promise(async resolve => {
        var res = await OtcService.getListOtcUsers(params)
        let otcListUsers = res.data
        ctx.dispatch('setPagination', res.pagination)
        let result = UtilService.transformTimes(otcListUsers)
        ctx.dispatch('setOtcListUsers', result)
        resolve(result)
      })
    },
    updateOtcUserStatus(_, data) {
      return new Promise(async resolve => {
        let res = await OtcService.updateOtcUserStatus(data)
        resolve(res)
      })
    },
  }
  
  export default { actions }
  