<template>
  <div class="main-card mb-3 card" :class="{'load-bar' : isSearching}">
    <div class="card-body">
      <h5 class="card-title">
        User KYC
      </h5>
      <div class="search-wrap">
        <div class="search-item">
          <label>Search</label>
          <input
            id="search-id"
            type="text"
            v-model="query.search"
            @keyup.enter="search"
          />
        </div>
        <div class="search-item">
          <label>Status</label>
          <el-select @change="search" v-model="query.status" placeholder="Type">
            <el-option
              v-for="item in ['ALL', 'SUBMITTED', 'REJECTED', 'APPROVED']"
              :key="item"
              :label="item"
              :value="item"
              class="text-capitalize">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <label>Buy Token</label>
          <el-select @change="search" v-model="query.buyToken" placeholder="BuyToken" clearable>
            <el-option
                v-for="item in ['ALEO', 'KAS']"
                :key="item"
                :label="item"
                :value="item"
                class="text-capitalize">
            </el-option>
          </el-select>
        </div>
        <div class="search-item d-flex">
          <button class="btn btn-primary" @click.prevent="search">{{$t('TEXT_SEARCH')}}</button>
        </div>

      </div>
    </div>
     <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="card-title card-header-title font-size-lg text-capitalize font-weight-normal">KYC Overview</div>
            <div class="table-responsive">
              <table class="align-middle text-truncate mb-0 table table-bordered  table-hover">
                <thead>
                <tr class="text-left">
                  <th>Email</th>
                  <th>Fullname</th>
                  <th>ID Number</th>
                  <th>DOB</th>
                  <th>Country</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
                </thead>
                <tbody v-if="affData.length">
                  <tr class="text-left" v-for="(item, index) in affData" :key="index" @click="openDetail(item)">
                    <td >{{ item.email }} <i class="el-icon-document-copy" @click.stop="doCopy(item.email)" style="cursor: pointer"></i></td>
                    <td >{{ item.kycFullname }}</td>
                    <td >{{ item.kycIdNumber }}</td>
                    <td >{{ item.kycDobTxt }}</td>
                    <td >{{ item.countryTxt }}</td>
                    <td >{{ item.kycType }}</td>
                    <td >{{ item.kycStatus }}</td>
                  </tr>
                </tbody>
                <NoData v-else/>
            </table>
             <el-pagination
              v-if="affData.length"
              layout="total, prev, pager, next"
              :page-count="pagination.total_pages"
              :page-size="pagination.size"
              :current-page="pagination.page"
              :total="pagination.total_records"
              @current-change="goToPage"
            ></el-pagination>
          </div>
        </div>
     </div>

     <el-dialog
      title="Confirm KYC"
      :visible.sync="dialogVisibleCreat"
      :before-close="handleClose"
      :closeOnClickModal="false"
      :destroy-on-close="true"
      class="medium"
    >
      <div>
        <form class="mx-auto from-update-broker" id="creatBrokerConfig" method="post" action="">
          <div class="">
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Fullname
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.kycFullname">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                ID Number
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.kycIdNumber">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                DOB
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                v-model="currentUser.kycDobTxt">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Country
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.countryTxt">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Kyc Type
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.kycType">
            </div>
            <div class="from-group row item-config image-kyc" v-if="currentUser.kycImage1">
              <label class="confirm_password col-sm-6">
                Front Image
              </label>
              <img :src="imageUrl + '' + currentUser.kycImage1" alt="Girl in a jacket" width="500" height="600">
            </div>
            <div class="from-group row item-config image-kyc" v-if="currentUser.kycImage2">
              <label class="confirm_password col-sm-6">
                Back Image
              </label>
              <img :src="imageUrl + '' + currentUser.kycImage2" alt="Girl in a jacket" width="500" height="600">
            </div>
            <div class="from-group row item-config image-kyc"  v-if="currentUser.kycImage3">
              <label class="confirm_password col-sm-6">
                Selfie Image
              </label>
              <img :src="imageUrl + '' + currentUser.kycImage3" alt="Girl in a jacket" width="500" height="600">
            </div>

            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Status
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.kycStatus">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Kyc Submitted Time
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.kycTimeSubmittedTxt">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Kyc Rejected Time
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.kycTimeRejectedTxt">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Kyc Approved Time
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text" disabled
                  v-model="currentUser.kycTimeApprovedTxt">
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Kyc Reject Message
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text"
                  v-model="form.rejectMessage">
            </div>
          </div>
          <div class="from-group text-center out-btn-update" v-if="isShowBtn">
              <button :disabled="isProcessing || !isValidatedForm" @click.prevent="confirmKyc()" class="btn btn-primary mr-sm-3">Approve</button>
              <button :disabled="isProcessing || !isValidatedForm" @click.prevent="rejectKyc()" class="btn btn-warning">Reject</button>
          </div>
        </form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";

import FilterData from "@/mixin/FilterData";
import NoData from '../components/NoData'
import CONFIG from '@/config/Config'
import Sticky from 'vue-sticky-directive'
import {copy} from "ionicons/icons";
export default {
  mixins: [FilterData],
  components: {
    NoData
  },
  directives: {Sticky},
  data() {
    return {
      imageUrl: '',
      dialogVisibleCreat: false,
      isSearching: false,
      affData: [],
      currentUser: {},
      isProcessing: false,
      form: {
        rejectMessage: '',
        id: '',
        status: ''
      }
    };
  },
  computed: {
    copy() {
      return copy
    },
    isShowBtn () {
      return this.currentUser && this.currentUser.kycStatus === 'SUBMITTED'
    },
    isValidatedForm () {
      return true
    },
    wallets () {
      return this.$store.getters.wallets
    },
    broker() {
      return this.$store.getters.broker;
    }
  },
  created() {
    this.imageUrl = CONFIG.IMAGE_URL
    const initQuery = {
      status: 'SUBMITTED'
    }
    const query = JSON.parse(JSON.stringify(this.$route.query));
    this.query = Object.assign(initQuery, query)
    this.query.status && this.fetch();
  },
  methods: {
    handleClose (done) {
      this.dialogVisibleCreat = false
      this.currentUser = {}
      done && done()
    },
    fetch () {
      let filterObj = this.buildQueryObj()
      if (this.query.status) filterObj.status = this.query.status
      if (filterObj.status === 'ALL') {
        delete filterObj.status
      }
      this.isSearching = true
      this.$store.dispatch('getUserKyc', { page: filterObj.page, params: filterObj }).then((rs) => {
        this.affData = rs.data
        this.isSearching = false
      })
    },
    openDetail (item) {
      this.currentUser = item
      this.form.id = item.id
      this.form.rejectMessage = item.kycRejectMessage
      this.dialogVisibleCreat = true
    },
    confirmKyc () {
      console.log('confirm kyc')
      this.form.status = 'APPROVED'
      this.$store.dispatch('confirmKyc', this.form).then((rs) => {
        this.isSearching = false
        if (rs.status) {
          this.fetch()
          this.dialogVisibleCreat = false
        }
      })
    },
    rejectKyc () {
      if (!this.form.rejectMessage) {
        this.$store.dispatch('showErrorMsg', 'You have to provide the reason why you reject this kyc')
        return
      }
      this.form.status = 'REJECTED'
      this.$store.dispatch('confirmKyc', this.form).then((rs) => {
        this.isSearching = false
        if (rs.status) {
          this.fetch()
          this.dialogVisibleCreat = false
        }
      })
    },
    doCopy (value) {
      this.$copyText(value).then(() => {
        this.$store.dispatch('showSuccessMsg', this.$t('TEXT_COPIED', 'Copied'))
      })
    }
  }
}
</script>
