import API from '@/api/KycService'
import UtilService from '../../../utils/UtilService'
var actions = {
  getKycInfo(_, params) {
    return new Promise(async resolve => {
      var res = await API.getKycInfo(params)
      let data = res.data || {}
      data.histories = UtilService.transformTimes(data.histories)
      resolve(data)
    })
  },
  UpdateKyc(_, data) {
    return new Promise(async resolve => {
      var res = await API.UpdateKyc(data)
      resolve(res)
    })
  }
}

export default { actions }
