import Vue from 'vue'
import Vuex from 'vuex'

import CommonModule from './modules/common/index'
import AuthModule from './modules/auth/index'
import KycModule from './modules/kyc/index'
import UserModule from './modules/user/index'
import BrokerModule from './modules/broker/index'
import LangModule from './modules/lang/index'
import WalletModule from './modules/wallet/index'
import OrderModule from './modules/order/index'
import OtcModule from './modules/otc/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    CommonModule,
    AuthModule,
    KycModule,
    UserModule,
    BrokerModule,
    LangModule,
    WalletModule,
    OrderModule,
    OtcModule
  }
})

export default store
