var actions = {
  setWallets ({ commit }, data) {
    commit('SET_WALLETS', data)
  },
  setTransactions ({ commit }, data) {
    commit('SET_TRANSACTIONS', data)
  },
  setTranfers ({ commit }, data) {
    commit('SET_TRANFERS', data)
  },
  setOrderStatus ({ commit }, data) {
    commit('SET_ORDER_STATUS', data)
  },
  setBalanceOverView ({ commit }, data) {
    commit('SET_BALANCE_OVER_VIEW', data)
  },
  setTokenRemaining ({ commit }, data) {
    commit('SET_TOKEN_REMAINING', data)
  },
  setTransactionConfirmStatus ({ commit }, data) {
    commit('SET_TRANSACTION_CONFIRM_STATUS', data)
  },
  setTransactionsStatus ({ commit }, data) {
    commit('SET_TRANSACTION_STATUS', data)
  },
  setTransactionsType ({ commit }, data) {
    commit('SET_TRANSACTION_TYPE', data)
  },
  setTransferStatus ({ commit }, data) {
    commit('SET_TRANSFER_STATUS', data)
  },
  setTransferType ({ commit }, data) {
    commit('SET_TRANSFER_TYPE', data)
  },
  setSymbols ({ commit }, symbols) {
    commit('SET_SYMBOLS', symbols)
  },
  setListWalletUserApiKey ({ commit }, data) {
    commit('SET_LIST_WALLET_USER_API_KEY', data)
  },
  setListWallets ({ commit }, data) {
    commit('SET_LIST_WALLETS', data)
  }
}

export default { actions }
