var state = {
  orders: [],
  orderOverview: [],
  orderTransactions: [],
  orderType: []
}

var getters = {
  orders: state => state.orders,
  orderOverview: state => state.orderOverview,
  orderTransactions: state => state.orderTransactions,
  orderType: state => state.orderType
}

var mutations = {
  SET_ORDERS: (state, data) => {state.orders = data},
  SET_ORDER_OVERVIEW: (state, data) => { state.orderOverview = data },
  SET_ORDER_DETAIL: (state, data) => { state.orderTransactions = data },
  SET_ORDER_TYPE: (state, data) => { state.orderType = data }
}

export default { state, getters, mutations }
