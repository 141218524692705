<template>
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper" @click.stop="isOpened = !isOpened">
        <div class="widget-content-left">
          <div class="widget-heading">{{user.fullName || "Your Fullname"}}</div>
          <div class="widget-subheading">{{user.email}}</div>
        </div>
        <div class="widget-content-left  ml-3 header-user-info">
          <i class="fa fa-angle-down ml-2 opacity-8"></i>
        </div>
        <div class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right" :class="{ 'show': isOpened }" v-click-outside="closePopup">
          <div class="dropdown-menu-header">
            <div class="dropdown-menu-header-inner bg-info">
              <div class="menu-header-image opacity-2" style="background-image: url('/assets/images/dropdown-header/city3.jpg');"></div>
              <div class="menu-header-content text-left">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading">{{user.fullName || "Your Fullname"}}</div>
                      <div class="widget-subheading opacity-8">{{user.email}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="nav flex-column">
            <li v-if="false" class="nav-item"><a href="#" class="nav-link">{{$t('TEXT_MY_ACCOUNT')}}</a></li>
            <li v-if="false" class="nav-item"><a href="#" class="nav-link">{{$t('TEXT_SETTINGS')}}</a></li>
            <li class="nav-item" v-if="false">
              <router-link :to="{ name: 'ChangePassword' }" class="nav-link">
                {{$t('TEXT_CHANGE_PASSWORD')}}
              </router-link>
            </li>
            <li v-if="false" class="nav-item"><a href="#" class="nav-link">{{$t('TEXT_LOGS')}}</a></li>
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item-btn nav-item">
              <button class="btn-wide btn-shadow btn btn-danger btn-sm"  @click="logout">{{$t('TEXT_LOGOUT')}}</button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="false" class="dropdown d-inline-block">
      <button class="btn btn-link" @click.stop="isOpened = !isOpened" >
        <img width="42" class="rounded-circle" src="/assets/images/avatars/1.jpg" alt="">
      </button>
      <div class="dropdown-menu dropdown-menu-right" :class="{ 'show': isOpened }" v-click-outside="closePopup">
        <div class="dropdown-menu-header">
          <div class="dropdown-menu-header-inner bg-primary">
            <div class="menu-header-content">
              <h4 class="mb-0">{{user.fullName || "Your Fullname"}}</h4>
              <p class="mb-0">{{user.email}}</p>
            </div>
          </div>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item"><a href="#" class="nav-link">{{$t('TEXT_MY_ACCOUNT')}}</a></li>
          <li class="nav-item"><a href="#" class="nav-link">{{$t('TEXT_SETTINGS')}}</a></li>
          <li class="nav-item">
            <router-link :to="{ name: 'ChangePassword' }" class="nav-link">
              {{$t('TEXT_CHANGE_PASSWORD')}}
            </router-link>
          </li>
          <li class="nav-item"><a href="#" class="nav-link">{{$t('TEXT_LOGS')}}</a></li>
          <li class="nav-item-divider nav-item"></li>
          <li class="nav-item-btn nav-item">
            <button class="btn-wide btn-shadow btn btn-danger btn-sm">{{$t('TEXT_LOGOUT')}}</button>
          </li>
        </ul>
      </div>
      <div v-if="false" class="widget-content-left">
        <div class="btn-group">
          <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn"
             @click.stop="isOpened = !isOpened">
            <img width="42" class="rounded-circle" src="/assets/images/avatars/1.jpg" alt="">
          </a>
          <div tabindex="-1" role="menu" aria-hidden="true"
               class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right"
               :class="{ 'show': isOpened }" v-click-outside="closePopup">
            <div class="dropdown-menu-header">
              <div class="dropdown-menu-header-inner bg-info">
                <div class="menu-header-image opacity-2"
                     style="background-image: url('assets/images/dropdown-header/city3.jpg');"></div>
                <div class="menu-header-content text-left">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">
                        <img width="42" class="rounded-circle" src="assets/images/avatars/1.jpg" alt="">
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{user.fullName || "FullName"}}
                        </div>
                        <div class="widget-subheading opacity-8">{{user.email}}
                        </div>
                      </div>
                      <div class="widget-content-right mr-2">
                        <button class="btn-pill btn-shadow btn-shine btn btn-focus"
                                @click="logout">{{$t('TEXT_LOGOUT')}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="scroll-area-xs" style="height: 150px;">
              <div class="scrollbar-container ps">
                <ul class="nav flex-column">
                  <li class="nav-item-header nav-item">{{$t('TEXT_ACTIVITY')}}
                  </li>
                  <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">{{$t('TEXT_CHAT')}}
                      <div class="ml-auto badge badge-pill badge-info">8
                      </div>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">{{$t('TEXT_RECOVER_PASSWORD')}}
                    </a>
                  </li>
                  <li class="nav-item-header nav-item">{{$t('TEXT_MY_ACCOUNT')}}
                  </li>
                  <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">{{$t('TEXT_SETTING')}}
                      <div class="ml-auto badge badge-success">{{$t('TEXT_NEW')}}
                      </div>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">{{$t('TEXT_MESSAGES')}}
                      <div class="ml-auto badge badge-warning">512
                      </div>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a href="javascript:void(0);" class="nav-link">{{$t('TEXT_LOGS')}}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <ul class="nav flex-column">
              <li class="nav-item-divider mb-0 nav-item"></li>
            </ul>
            <div class="grid-menu grid-menu-2col">
              <div class="no-gutters row">
                <div class="col-sm-6">
                  <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning">
                    <i class="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i> {{$t('TEXT_MESSAGE_INBOX')}}
                  </button>
                </div>
                <div class="col-sm-6">
                  <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                    <i class="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i>
                    <b>{{$t('TEXT_SUPPORT_TICKETS')}}</b>
                  </button>
                </div>
              </div>
            </div>
            <ul class="nav flex-column">
              <li class="nav-item-divider nav-item">
              </li>
              <li class="nav-item-btn text-center nav-item">
                <button class="btn-wide btn btn-primary btn-sm">
                  {{$t(TEXT_OPEN_MESSAGES)}}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ClickOutside from '@/directives/VueClickOutside'

  export default {
    directives: {
      ClickOutside
    },
    data() {
      return {
        isOpened: false
      }
    },
    computed: {
      user() {
        return this.$store.getters.user
      }
    },

    methods: {
      logout() {
        this.$store.dispatch('logOut')
      },
      closePopup() {
        if (this.isOpened) this.isOpened = false
      }
    }
  }
</script>
