import router from '@/router'
import LocalStorage from '@/utils/LocalStorage'
import UtilService from '@/utils/UtilService'
import store from '@/store'
const RouterInterceptor = {
  install () {
    router.beforeEach(async (to, from, next) => {
      // Check page is required authenticated
      let routeRole = to.matched.find(record => record.meta.requiresAuth)
      if (to.matched.some(record => record.meta.requiresAuth)) {
        let tokenObj = LocalStorage.getToken()
        // If token is empty, redirect to login page
        if (!tokenObj) {
          next({
            name: 'Login',
            query: { nextUrl: to.fullPath }
          })
        } else if (!routeRole || !routeRole.meta || !routeRole.meta.roles || !routeRole.meta.roles.length) {
          next()
        } else { // Next if token exist
          next()
          // let adminRole = store.getters.adminRole
          // if (!adminRole) {
          //   adminRole = await store.dispatch('getAdminRole')
          // }
          // console.log(adminRole)
          // console.log(routeRole.meta.roles)
          // if (routeRole.meta.roles.indexOf(adminRole) === -1) {
          //   next({
          //     name: 'PageNotFound'
          //   })
          // } else {
          //   next()
          // }
        }
      } else { // Page is not required authenticated
        next()
      }
    })
  }
}
export default RouterInterceptor
