<template>
  <div class="main-card mb-3 card" :class="{'load-bar' : isSearching}">
    <div class="card-body">  
      <h5 class="card-title">
        User Affiliate
      </h5>
      <div class="search-wrap">
        <div class="search-item">
          <label>Code</label>
          <input
            id="search-id"
            type="text"
            v-model="query.code"
            @keyup.enter="search"
          />
        </div>
        <div class="search-item">
          <label>Level</label>
          <el-select @change="search" v-model="query.level" placeholder="Type">
            <el-option
              v-for="item in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              :key="item"
              :label="item"
              :value="item"
              class="text-capitalize">
            </el-option>
          </el-select>
        </div>
        <div class="search-item d-flex">
          <button class="btn btn-primary" @click.prevent="search">{{$t('TEXT_SEARCH')}}</button>
        </div>
      
      </div>
    </div>
     <div class="main-card mb-3 card">
          <div class="card-body">
            <div class="card-title card-header-title font-size-lg text-capitalize font-weight-normal">Affiliate Overview</div>
            <div class="table-responsive">
              <table class="align-middle text-truncate mb-0 table table-bordered  table-hover">
                <thead>
                <tr class="text-center">
                  <th>Code</th>
                  <th>Commission</th>
                  <th>Email</th>
                  <th>Level</th>
                  <th>RefCode</th>
                  <th>Role</th>
                  <th>Total Buy</th>
                  <th>Username</th>
                </tr>
                </thead>
                <tbody>
                  <tr class="text-center" v-for="(item, index) in affData" :key="index">
                    <td >{{ item.code }}</td>
                    <td >{{ item.commission }}</td>
                    <td >{{ item.email }}</td>
                    <td >{{ item.level }}</td>
                    <td >{{ item.refCode }}</td>
                    <td >{{ item.role }}</td>
                    <td >{{ item.totalBuy }}</td>
                    <td >{{ item.username }}</td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
     </div>
  </div>
</template>

<script>
import Mapp from "@/utils/mapUtil";
import _ from "lodash";
import FilterData from "@/mixin/FilterData";
import NoData from '../components/NoData'
import Sticky from 'vue-sticky-directive'
export default {
  mixins: [FilterData],
  components: {
    NoData
  },
  directives: {Sticky},
  data() {
    return {
      isSearching: false,
      affData: []
    };
  },
  computed: {
    wallets () {
      return this.$store.getters.wallets
    },
    broker() {
      return this.$store.getters.broker;
    }
  },
  created() {
    const initQuery = {
      level: 1
    }
    const query = JSON.parse(JSON.stringify(this.$route.query));
    this.query = Object.assign(initQuery, query)
    this.query.level && this.query.code && this.fetch();
  },
  methods: {
    formatNumber(value) {
      let newNumber = value.toString().split(".")
      newNumber[0] = newNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return newNumber.join(".")
    },
    fetch () {
      let filterObj = this.buildQueryObj()
      this.isSearching = true
      this.$store.dispatch('getUserAff', filterObj).then((rs) => {
        this.affData = rs.data[this.query['level']]
        this.isSearching = false
      })
    },
    exportExcelBalance () {
      this.exportExcel('balance')
    }
  }
}
</script>