import Vue from 'vue'
import Router from 'vue-router'

/* Layout */
import DashboardLayout from '@/views/layout/DashboardLayout'
import AuthLayout from '@/views/layout/AuthLayout'

// Pages
import LoginComponent from '@/views/auth/login'
import DashboardComponent from '@/views/dashboard/index'
import CurrencyComponent from '@/views/broker/Currency'
import NotfoundComponent from '@/views/404'
import CommissionDetailHistory from '@/views/user/CommissionDetail'
import TransactionHistory from '@/views/user/TransactionHistory'
import BuyHistory from '@/views/user/BuyHistory'
import UserAff from '@/views/user/UserAff'
import UserKYC from '@/views/user/UserKYC'
import UserAffTree from '@/views/user/UserAffTree'
Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardLayout,
    redirect: { name: 'Login' },
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    component: AuthLayout,
    hidden: true,
    meta: { requiresAuth: false },
    children: [
      {
        name: 'Login',
        path: '/',
        component: LoginComponent
      }
    ]
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    redirect: { name: 'Dashboard' },
    meta: { title: 'TEXT_DASHBOARD', requiresAuth: true, roles: ['ADMIN1', 'ADMIN2'] },
    children: [{
      path: 'index',
      name: 'Dashboard',
      component: DashboardComponent,
      meta: { title: 'ROUTE_DASHBOARD', icon: 'metismenu-icon pe-7s-home', subTitle: 'Chart and analytics' }
    }]
  },
  {
    path: '/user',
    component: DashboardLayout,
    redirect: { name: 'Aff' },
    meta: { title: 'USER_MANAGEMENT', icon: 'el-icon-coin', requiresAuth: true, roles: ['ADMIN1'] },
    children: [
      {
        path: 'aff',
        name: 'Aff',
        component: UserAff,
        meta: { title: 'User Aff', icon: 'metismenu-icon pe-7s-wallet' }
      },
      {
        path: 'tree',
        name: 'tree',
        component: UserAffTree,
        meta: { title: 'User Aff Tree', icon: 'metismenu-icon pe-7s-user' }
      },
      {
        path: 'kyc',
        name: 'kyc',
        component: UserKYC,
        meta: { title: 'User KYC', icon: 'metismenu-icon pe-7s-user' }
      },
      {
        path: 'detail-user',
        name: 'CommissionDetailHistory',
        component: CommissionDetailHistory,
        meta: { title: 'Detail User', icon: 'metismenu-icon pe-7s-wallet', subTitle: 'Detail User' }
      }
    ]
  },
  {
    path: '/transaction',
    component: DashboardLayout,
    redirect: { name: 'Transaction' },
    meta: { title: 'Transaction Management', icon: 'el-icon-coin', requiresAuth: true, roles: ['ADMIN1'] },
    children: [
      {
        path: 'list',
        name: 'Transaction',
        component: TransactionHistory,
        meta: { title: 'ROUTE_TRANSACTION', icon: 'metismenu-icon pe-7s-refresh-2' }
      },
      {
        path: 'buy-history',
        name: 'BuyHistory',
        component: BuyHistory,
        meta: { title: 'ROUTE_BUY_HISTORY', icon: 'metismenu-icon pe-7s-cart' }
      },
    ]
  },
  {
    path: '/system-config',
    component: DashboardLayout,
    redirect: { name: 'Broker' },
    meta: { title: 'SYSTEM_CONFIG', icon: 'el-icon-coin', requiresAuth: true, roles: ['ADMIN1'] },
    children: [
      {
        path: 'rates',
        name: 'Currency',
        component: CurrencyComponent,
        meta: { title: 'Hash Rate', icon: 'metismenu-icon pe-7s-cash' }
      },
    ]
  },
  {
    path: '/404',
    component: NotfoundComponent,
    hidden: true,
    name: 'PageNotFound',
    meta: { requiresAuth: false }
  },
  { path: '*', redirect: '/404', hidden: true, meta: { requiresAuth: false } }
]

// Router catch exception
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location)
    .catch(() => console.log('Routing is not changed'))
}

export default new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  linkActiveClass: "mm-active",
  routes: routes
})
