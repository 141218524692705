import OrderService from '@/api/OrderService'
import UtilService from '../../../utils/UtilService'
import router from '../../../router'
var actions = {
  getOrderType (ctx) {
    return new Promise(async resolve => {
      var res = await OrderService.getOrderType()
      let result = res.data
      ctx.dispatch('setOrderType', result)
      resolve(res.data)
    })
  },
  getOrders (ctx, params) {
    return new Promise(async resolve => {
      var res = await OrderService.getOrders(params)
      let wallets = res.data
      if (router.currentRoute.name === 'OrderList') {
        ctx.dispatch('setPagination', res.pagination)
      }
      let result = UtilService.transformOrders(wallets)
      ctx.dispatch('setOrders', result)
      resolve(result)
    })
  },
  getOrderOverview (ctx, params) {
    return new Promise(async resolve => {
      let res = await OrderService.getOrderOverview(params)
      let result = res.data
      ctx.dispatch('setOrderOverview', result)
      resolve(result)
    })
  },
  getOrderDetail(ctx, params) {
    return new Promise(async resolve => {
      var res = await OrderService.getOrderDetail(params)
      var orderOrderDetail = res.data || []
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformTimes(orderOrderDetail)
      ctx.dispatch('setOrderDetail', result)
      resolve(result)
    })
  },
}

export default { actions }
