
import CONFIG from '@/config/Config'
import { Post, Delete } from '@/utils/RequestService'

var apiUrl = CONFIG.API_POINT
var service = {
  login: data => Post(`${apiUrl}auth/login-admin`, data),
  logout: data => Delete(`${apiUrl}/admin/logout`, data),
  changePassword: data => Post(`${apiUrl}/auth/change-password`, data)
}

export default service