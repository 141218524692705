var state = {
    OtcFiat: [],
    OtcCurrency: [],
    OtcCountry: [],
    OtcOrderList: [],
    OtcOrderStatus: [],
    OtcOrderType: [],
    listFiatCode: [],
    listOtcAdvance: [],
    ListFiatByBroker: [],
    ListOtcPaymentMethod: [],
    ListOtcSystemConfig: [],
    ListBrokerFilter: [],
    OtcListUsers: [],
  }
  
  var getters = {
    OtcFiat: state => state.OtcFiat,
    OtcCurrency: state => state.OtcCurrency,
    OtcCountry: state => state.OtcCountry,
    OtcOrderList: state => state.OtcOrderList,
    OtcOrderStatus: state => state.OtcOrderStatus,
    OtcOrderType: state => state.OtcOrderType,
    listFiatCode: state => state.listFiatCode,
    OtcListUsers: state => state.OtcListUsers,
    listOtcAdvance: state => state.listOtcAdvance,
    ListFiatByBroker: state => state.ListFiatByBroker,
    ListOtcPaymentMethod: state => state.ListOtcPaymentMethod,
    ListOtcSystemConfig: state => state.ListOtcSystemConfig,
    ListBrokerFilter: state => state.ListBrokerFilter,
  }
  
  var mutations = {
    SET_OTC_FIAT: (state, data) => {state.OtcFiat = data},
    SET_OTC_CURRENCY: (state, data) => {state.OtcCurrency = data},
    SET_OTC_COUNTRY: (state, data) => {state.OtcCountry = data},
    SET_OTC_ORDER_LIST: (state, data) => {state.OtcOrderList = data},
    SET_OTC_ORDER_STATUS: (state, data) => {state.OtcOrderStatus = data},
    SET_OTC_ORDER_TYPE: (state, data) => {state.OtcOrderType = data},
    SET_LIST_FIAT_CODE: (state, data) => {state.listFiatCode = data},
    SET_LIST_OTC_ADVANCE: (state, data) => {state.listOtcAdvance = data},
    SET_LIST_FIAT_BUY_BROKER: (state, data) => {state.ListFiatByBroker = data},
    SET_LIST_OTC_PAYMENT_METHOD: (state, data) => {state.ListOtcPaymentMethod = data},
    SET_LIST_OTC_SYSTEM_CONFIG: (state, data) => {state.ListOtcSystemConfig = data},
    SET_LIST_BROKER_FILTER: (state, data) => {state.ListBrokerFilter = data},
    SET_LIST_OTC_USERS: (state, data) => {state.OtcListUsers = data},
  }
  
  export default { state, getters, mutations }
  