import CONFIG from '@/config/Config'
import { Get, Post } from '@/utils/RequestService'

const apiUrl = CONFIG.API_POINT

var service = {
  getKycInfo: params => Get(`${apiUrl}/kyc/kyc-info`, params),
  UpdateKyc: data => Post(`${apiUrl}/kyc/update-kyc-status`, data),
}

export default service
