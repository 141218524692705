import LocalStorage from '@/utils/LocalStorage'
const actions = {
  setBrokers ({ commit }, brokers) {
    commit('SET_BROKERS', brokers)
  },
  setUserBroker ({ commit }, userBroker) {
    commit('SET_USER_BROKER', userBroker)
  },
  setBroker ({ commit }, broker) {
    commit('SET_BROKER_ID', broker)
    LocalStorage.setItem('broker', broker)
  },
  setBrokersCurrency ({ commit }, brokersCurrency) {
    commit('SET_BROKER_CURRENCY', brokersCurrency)
  },
  setBrokerConfig ({ commit }, brokerConfig) {
    commit('SET_BROKER_CONFIG', brokerConfig)
  },
  setBrokersConfig ({ commit }, brokersConfig) {
    commit('SET_BROKERS_CONFIG', brokersConfig)
  },
  setListCurrency ({ commit }, listCurrencies) {
    commit('SET_LIST_CURRENCY', listCurrencies)
  },
  setListAllCurrency ({ commit }, listAllCurrencies) {
    commit('SET_LIST_ALL_CURRENCY', listAllCurrencies)
  },
  setListSystemCurrency ({ commit }, listSystemCurrencies) {
    commit('SET_LIST_SYSTEM_CURRENCY', listSystemCurrencies)
  },
  setListBrokers ({ commit }, listBrokers) {
    commit('SET_LIST_BROKERS', listBrokers)
  },
  setBrokersSymbol ({ commit }, listBrokersSymbol) {
    commit('SET_BROKERS_SYMBOL', listBrokersSymbol)
  },
  setListSymbol ({ commit }, listSymbol) {
    commit('SET_LIST_SYMBOL', listSymbol)
  },
  setListSymbols ({ commit }, listSymbols) {
    commit('SET_LIST_SYMBOLS', listSymbols)
  },
  setListCurrencyFilter ({ commit }, listCurrencyFilter) {
    commit('SET_LIST_CURRENCY_FILTER', listCurrencyFilter)
  },
  setListCoinInfo ({ commit }, listCoinInfo) {
    commit('SET_LIST_COIN_INFO', listCoinInfo)
  },
  setListLimitOrder ({ commit }, listLimitOrder) {
    commit('SET_LIST_LIMIT_ORDER', listLimitOrder)
  },
  setListFeeType ({ commit }, listFeeType) {
    commit('SET_LIST_FEE_TYPE', listFeeType)
  },
  setListBrokerWithdrawLevel ({ commit }, listBrokerWithdrawLevel) {
    commit('SET_LIST_BROKER_WITHDRAW_LEVEL', listBrokerWithdrawLevel)
  },
  setBrokersAccount ({ commit }, brokersAccount) {
    commit('SET_BROKERS_ACCOUNT', brokersAccount)
  },
  setBrokersWallet ({ commit }, brokersWallet) {
    commit('SET_BROKERS_WALLET', brokersWallet)
  },
  setListBrokerCurrencyExchangeUsdt ({ commit }, listBrokerCurrencyExchangeUsdt) {
    commit('SET_BROKER_CURRENCY_EXCHANGE_USDT', listBrokerCurrencyExchangeUsdt)
  },
}

export default { actions }
