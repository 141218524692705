var actions = {
  setOrders ({ commit }, data) {
    commit('SET_ORDERS', data)
  },
  setOrderOverview ({ commit }, data) {
    commit('SET_ORDER_OVERVIEW', data)
  },
  setOrderDetail ({ commit }, data) {
    commit('SET_ORDER_DETAIL', data)
  },
  setOrderType ({ commit }, data) {
    commit('SET_ORDER_TYPE', data)
  }
}

export default { actions }
