import axios from 'axios'
import LocalStorage from '@/utils/LocalStorage'
import i18n from '@/plugin/i18n'
import store from '@/store/index'
import CONSTANT from '@/config/Constant'

// create an axios instance
const http = axios.create({
})
export default http

http.interceptors.request.use(
  async config => {
    // Do something before request is sent
    let tokenObj = LocalStorage.getToken()
    const url = config.url
    const isLanguageLink = url.indexOf(CONSTANT.LANGUAGE_LINK) !== -1
    if (tokenObj && tokenObj && !isLanguageLink) {
      config.headers['Authorization'] = 'Bearer ' + tokenObj
    }
    return config
  },
  error => {
    console.log(error)
    // Do something with request error
    Promise.resolve(false)
  }
)
http.interceptors.response.use(
  response => {
    var data = response.data
    var storeAction = data.status ? 'showSuccessMsg' : 'showErrorMsg'
    data && data.message && store.dispatch(storeAction, i18n.t(data.message))
    if (data.data && data.data.currentPage) {
      data.pagination = {
        page: data.data.currentPage,
        size: data.data.pageSize,
        total: data.data.totalPages
      }
    }
    if(data.data && data.data.data) data.data = data.data.data
    return data
  },
  error => {
    // Refresh token
    let rs = error.response
    let message = rs && rs.data && rs.data.message || 'error'
    if (rs && rs.status === CONSTANT.UNAUTHORIZED_CODE) {
      store.dispatch('logout')
    } else if (rs && rs.status === CONSTANT.INVALID_TOKEN) {
      store.dispatch('forceLogout')
    } else if (rs && rs.status === CONSTANT.INVALID_ROLE) {
      store.dispatch('showErrorMsg', i18n.t('MSG_NOT_HAVE_PERMISSION'))
    }
    message &&
      store.dispatch('showErrorMsg', message)
    return Promise.resolve(false)
  }
)
