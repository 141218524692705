var actions = {
  setOtcFiat ({ commit }, OtcFiat) {
    commit('SET_OTC_FIAT', OtcFiat)
  },
  setOtcCurrency ({ commit }, OtcCurrency) {
    commit('SET_OTC_CURRENCY', OtcCurrency)
  },
  setOtcCountry ({ commit }, OtcCountry) {
    commit('SET_OTC_COUNTRY', OtcCountry)
  },
  setOtcOrderType ({ commit }, OtcOrderType) {
    commit('SET_OTC_ORDER_TYPE', OtcOrderType)
  },
  setListFiatCode ({ commit }, listFiatCode) {
    commit('SET_LIST_FIAT_CODE', listFiatCode)
  },
  setOtcOrderStatus ({ commit }, otcOrderStatus) {
    commit('SET_OTC_ORDER_STATUS', otcOrderStatus)
  },
  setOtcListOrder ({ commit }, OtcOrderList) {
    commit('SET_OTC_ORDER_LIST', OtcOrderList)
  },
  setListOtcAdvance ({ commit }, listOtcAdvance) {
    commit('SET_LIST_OTC_ADVANCE', listOtcAdvance)
  },
  setListFiatByBroker ({ commit }, ListFiatByBroker) {
    commit('SET_LIST_FIAT_BUY_BROKER', ListFiatByBroker)
  },
  setListOtcPaymentMethod ({ commit }, ListOtcPaymentMethod) {
    commit('SET_LIST_OTC_PAYMENT_METHOD', ListOtcPaymentMethod)
  },
  setListOtcSystemConfig ({ commit }, ListOtcSystemConfig) {
    commit('SET_LIST_OTC_SYSTEM_CONFIG', ListOtcSystemConfig)
  },
  setListBrokerFilter ({ commit }, ListBrokerFilter) {
    commit('SET_LIST_BROKER_FILTER', ListBrokerFilter)
  },
  setOtcListUsers ({ commit }, OtcListUsers) {
  commit('SET_LIST_OTC_USERS', OtcListUsers)
  }
}

export default { actions }