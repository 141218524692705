<template>
  <div class="main-card mb-3 card">
    <div class="card-body">
      <div class="row">
        <h5 class="card-title col-sm-6">Currency List</h5>
        <div class="button-creat col-sm-6 text-right">
          <button data-v-10437314="" type="button" class="mb-2 btn-icon btn btn-primary" @click="onCreat()">Update Hash Rate</button>
        </div>
      </div>
      <div class="search-wrap">
        <div class="search-item">
          <label>Currency</label>
          <el-select @change="search" v-model="form.currency" placeholder="Type">
            <el-option
              v-for="item in listCurrencies"
              :key="item"
              :label="item"
              :value="item"
              class="text-capitalize">
            </el-option>
          </el-select>
        </div>
        <div class="search-item d-flex">
          <label>Current hash rate&nbsp;</label>
          <!-- <button class="btn btn-primary" @click.prevent="search">{{$t('TEXT_SEARCH')}}</button> -->
           ({{ rateAleo }})
        </div>
      
      </div>
      <div class="classic-table no-margin small" sticky-container>
        <dl>
          <dt v-sticky sticky-offset="{top: 60}">
            <!-- <div class="w-30"></div> -->

            <div v-if="form.currency === 'ALEO'">Total</div>
            <div v-if="form.currency === 'ALEO'">Rate mining</div>
            <div>Hash Rate</div>
            <div>Time</div>

          </dt>
          <template v-if="rateConfigs.length">
            <dd v-for="(item, key) in rateConfigs" :key="key">

              <div v-if="form.currency === 'ALEO'" class="text-primary">{{item.total}}</div>
              <div v-if="form.currency === 'ALEO'" class="text-primary">{{item.rateMining}}</div>

              <div class="text-primary">{{item.hashRate}}</div>
              <div class="text-primary">{{item.beautyUpdatedAt}}</div>

            </dd>
          </template>
          <NoData v-else></NoData>
        </dl>
      </div>
    </div>
    <!-- <el-dialog
      :title="$t('TEXT_EDIT_CURRENCY')"
      width="50%"
      :visible.sync="dialogVisibleEdit"
      :before-close="handleClose"
      :closeOnClickModal="false"
    >
        <div>
        <form class=" col-sm-12 mx-auto from-update-broker" id="updateBrokerConfig" method="post" action=""
            @submit.prevent="onSubmit">
            <div class="" v-for="(value, key) in currency" :key="key">
                <div class="from-group row item-config" v-if="typeof value=== 'boolean'">
                    <label class="confirm_password col-sm-6">
                        {{key}}:
                    </label>
                    <el-switch class="col-sm-6 el-switch" v-model="form[key]"></el-switch>
                </div>
                <div class="from-group row item-config" v-else :hidden="key === 'createdAt' || key === 'id'">
                    <label class="confirm_password col-sm-6">
                        {{key}}:
                    </label>
                    <input class="value-change form-control form-control-sm col-sm-6" type="text" v-if="key==='numberDecimal'"
                        v-model="form[key]"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')">
                    <input class="value-change form-control form-control-sm col-sm-6" type="text" v-else
                        v-model="form[key]">
                </div>
            </div>
          <div class="from-group text-center out-btn-update">
            <button type="submit"
                    :disabled="isProcessing"
                    class="btn btn-primary">{{$t('BTN_UPDATE')}}
            </button>
          </div>
        </form>
      </div>
    </el-dialog> -->
    <el-dialog
      :title="$t('TEXT_CREATE_CURRENCY')"
      class="medium"
      :visible.sync="dialogVisibleCreat"
      :before-close="handleClose"
      :closeOnClickModal="false"
    >
    <div>
        <form class="mx-auto from-update-broker" id="creatBrokerConfig" method="post" action="">
          <div class="">
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Currency(*)
              </label>
              <input class="value-change form-control form-control-sm col-sm-6" type="text"
                    v-model="form.currencyBuy" disabled>
            </div>
            <div class="from-group row item-config">
              <label class="confirm_password col-sm-6">
                Rate {{ form.currencyBuy }}(*)
              </label>
              <InputNumber v-model="form.hashRate"/>
            </div>
          </div>

          <div class="from-group text-center out-btn-update">
              <button :disabled="isProcessing || !isValidForm" @click.prevent="creatCurrency()" class="btn btn-primary">{{$t('BTN_CREATE')}}</button>
          </div>
        </form>
      </div>
    </el-dialog>
</div>
</template>

<script>
  import CONFIG from '@/config/Config'
  import store from "../../store"
  import NoData from '../components/NoData'
  import Sticky from 'vue-sticky-directive'
  import CONSTANT from "@/config/Constant"
  import InputNumber from '@/views/components/InputNumber'
import UtilService from '../../utils/UtilService'
  export default {
    components: {
      NoData,
      InputNumber
    },
    directives: { Sticky },
    data () {
      return {
        isLoading: false,
        dialogVisible: false,
        dialogVisibleEdit: false,
        dialogVisibleCreat: false,
        active: true,
        form: {
          currencyBuy: '',
          minBuy: null,
          maxBuy: null,
          isAllowBuy: false,
          bscChainId: null,
          hashRate: null,
          currency: ''
        },
        currency: {},
        text: 'TEXT_UPDATE_BROKER_CURRENCY',
        textCreat: 'TEXT_CREAT_BROKER_CURRENCY',
        isProcessing:false,
        searchTxt: '',
        regex: /[A-Za-z!@#$%^&*()_+_]/,
      }
    },
    computed: {
      rateAleo () {
        return this.$store.getters.rateAleo
      },
      broker () {
        return this.$store.getters.broker
      },
      listCurrencies () {
        return this.$store.getters.listAllCurrencies
      },
      isValidName() {
        return this.form.currencyBuy
      },
      isValidNumberDecimal() {
        if (!this.regex.test(this.form.numberDecimal)) {
          return String(this.form.numberDecimal)
        }
      },
      isValidForm () {
        return  this.form.hashRate
      },
      rateConfigs () {
        return this.$store.getters.rateConfigs
      }
    },
    created () {
      this.fetch()
    },
    watch: {
      broker (n, o) {
        n && this.fetch()
      },
      // brokerCurrency (n, o) {
      //     this.form = JSON.parse(JSON.stringify(n))
      // },
      currency(n, o) {
        this.form = JSON.parse(JSON.stringify(n))
      },
    //   creatCurrency(n,o) {
    //     this.formCreat = JSON.parse(JSON.stringify(n))
    //   }
    },
    watch: {
      'form.currency' (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          this.fetch()
        }
      }
    },
    methods: {
      search(currency) {
      },
      fetch () {
        if (!this.listCurrencies.length) this.$store.dispatch('getListAllCurrency' ).then(rs => {
          if (!this.form.currency) this.form.currency = rs[0]
        })
        if (this.form.currency) {
          const data = {
            page: 1,
            currency: this.form.currency
          }
          this.$store.dispatch('getRateHistoryConfigs', data)
          this.$store.dispatch('getRateAleo', this.form.currency)
        }
      },
      handleClose (done) {
        this.dialogVisible = false
        this.dialogVisibleCreat = false
        this.currency = {}
        done && done()
      },
      async onDetail (item) {
        this.currency = item
        this.dialogVisible = true
        this.isLoading = false
      },
      async onEdit (item) {
        this.currency = item
        this.dialogVisibleEdit = true
        this.isLoading = false
      },
      async onCreat() {
        // this.$store.dispatch('getListSymbol')
        this.dialogVisibleCreat = true
        this.form.currencyBuy = this.form.currency
        this.isLoading = false
      },
      changeActiveBrokerSymbol(item) {
        this.itemBrokerSymbol = item
        let textActive = !item.active
          ? this.$t('TEXT_UNACTIVE_CURRENCY') + ': ' + item.code
          : this.$t('TEXT_ACTIVE_CURRENCY') + ': ' + item.code
        this.$confirm(textActive, this.$t('TEXT_WARNING'), {
          confirmButtonText: this.$t('BTN_OK'),
          cancelButtonText: this.$t('BTN_CANCEL'),
          type: "warning"
        })
        .then(() => {
          let data = {
            code: item.code,
            active: item.active,
            broker: this.broker,
          };
          this.$store.dispatch("updateBrokerSymbolStatus", data).then(rs => {
            this.fetch()
          })
        })
        .catch(() => {
          this.itemBrokerSymbol.active = !item.active
        })
      },
      async creatCurrency() {
        if (this.isValidForm) {
          this.isProcessing = true
          this.$store.dispatch('addConfig', this.form).then(rs => {
            if (rs.status) {
              this.fetch()
              this.form.hashRate = ''
              this.dialogVisibleCreat = false
            }
            this.isProcessing = false
          })
        }
      },
      async onSubmit () {
        if (this.isValidForm) {
          this.isProcessing = true
          this.form.broker = this.broker
          this.$store.dispatch('updateBrokerSymbol', this.form).then(rs => {
            if(rs.status) {
              this.fetch()
              this.dialogVisibleEdit = false
            }
            this.isProcessing = false
          })
        }
      },
    }
  }
</script>
