import Cookies from 'js-cookie'

const state = {
  sidebar: {
    opened: !+Cookies.get('sidebarStatus'),
    withoutAnimation: false
  },
  device: 'desktop',
  googleRecaptchaKey: '',
  routes: [],
  isOpenedSidebar: true,
  isOpenedMBSidebar: false,
  isOpenMBHeader: false,
  pagination: {},
  configs: {},
  rateConfigs: [],
  rateAleo: {}
}

const getters = {
  sidebar: state => state.sidebar,
  device: state => state.device,
  googleRecaptchaKey: state => state.googleRecaptchaKey,
  routes: state => state.routes,
  isOpenedSidebar: state => state.isOpenedSidebar,
  pagination: state => state.pagination,
  isOpenedMBSidebar: state => state.isOpenedMBSidebar,
  isOpenMBHeader: state => state.isOpenMBHeader,
  configs: state => state.configs,
  rateConfigs: state => state.rateConfigs,
  rateAleo: state => state.rateAleo
}

const mutations = {
  SET_RATE_ALEO: (state, data) => {
    state.rateAleo = data
  },
  SET_RATE_CONFIGS: (state, data) => {
    state.rateConfigs = data
  },
  TOGGLE_SIDEBAR: state => {
    state.isOpenedSidebar = !state.isOpenedSidebar
  },
  TOGGLE_MB_SIDEBAR: state => {
    state.isOpenedMBSidebar = !state.isOpenedMBSidebar
  },
  TOGGLE_MB_HEADER: state => {
    state.isOpenMBHeader = !state.isOpenMBHeader
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 1)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_GG_RECAPTCHA: (state, data) => {
    state.googleRecaptchaKey = data
  },
  SET_ROUTES: (state, routes) => {
    state.routes = routes
  },
  SET_PAGINATION: (state, data) => {
    state.pagination = data
  }
  // SET_SYS_CONFIG: (state, data) => {
  //   state.configs = data
  //   state.googleRecaptchaKey = data.googleReCaptchaKey
  // }
}

export default { state, getters, mutations }
