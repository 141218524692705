import API from '@/api/BrokerService'
import BrokerService from '@/api/BrokerService'
import LocalStorage from '@/utils/LocalStorage'
import _ from 'lodash'
import UtilService from '../../../utils/UtilService'
const actions = {
  getBrokers (ctx) {
    return new Promise(async resolve => {
      var res = await API.getBrokers()
      let brokers = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setBrokers', brokers)
      var broker = LocalStorage.getItem('broker')
      let brokerFromCache = _.find(brokers, temp => {
        return temp.broker === broker
      })
      broker = brokerFromCache ? broker : brokers[0].broker
      ctx.dispatch('setBroker', broker)
      resolve(brokers)
    })
  },
  getBrokersCurrency (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getBrokersCurrency(params)
      let result = res.data
      ctx.dispatch('setBrokersCurrency', result)
      resolve(result)
    })
  },
  // getListBrokers (ctx) {
  //   return new Promise(async resolve => {
  //     var res = await API.getListBrokers()
  //     let result = UtilService.transformTimes(res.data || [])
  //     ctx.dispatch('setListBrokers', result)
  //     resolve(result)
  //   })
  // },
  getBrokerConfig (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getBrokerConfig(params)
      ctx.dispatch('setBrokerConfig', res.data || {})
      resolve(res.data)
    })
  },
  getBrokersConfig (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getBrokersConfig(params)
      ctx.dispatch('setBrokersConfig', res.data || {})
      resolve(res.data)
    })
  },
  getListCurrency (ctx) {
    return new Promise(async resolve => {
      var res = await API.getListCurrency()
      let currency = res.data
      ctx.dispatch('setListCurrency', currency)
      resolve(currency)
    })
  },
  getListCoinInfo (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListCoinInfo(params)
      let coinInfo = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setListCoinInfo', coinInfo)
      resolve(coinInfo)
    })
  },
  getListLimitOrder (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListLimitOrder(params)
      let listLimitOrder = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setListLimitOrder', listLimitOrder)
      resolve(listLimitOrder)
    })
  },
  getListAllCurrency (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListAllCurrency(params)
      let currency = res.data
      console.log("🚀 ~ getListAllCurrency ~ currency:", currency)
      ctx.dispatch('setListAllCurrency', currency)
      resolve(currency)
    })
  },
  addCurrency(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addCurrency(data)
      resolve(res)
    })
  },
  addCoin(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addCoin(data)
      resolve(res)
    })
  },
  addLimitOrder(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addLimitOrder(data)
      resolve(res)
    })
  },
  updateSymbol(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateSymbol(data)
      resolve(res)
    })
  },
  updateCoinInfo(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateCoinInfo(data)
      resolve(res)
    })
  },
  updateLimitOrder(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateLimitOrder(data)
      resolve(res)
    })
  },
  saveConfig (__, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.saveConfig(data)
      resolve(res)
    })
  },
  updateBrokerCurrencyStatus(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerCurrencyStatus(data)
      resolve(res)
    })
  },
  updateBrokerCurrency(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerCurrency(data)
      resolve(res)
    })
  },
  addBrokerCurrency(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addBrokerCurrency(data)
      resolve(res)
    })
  },
  getBrokersSymbol (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getBrokersSymbol(params)
      let result = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setBrokersSymbol', result)
      resolve(result)
    })
  },
  getListSymbols (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListSymbols()
      ctx.dispatch('setListSymbols', res.data)
      resolve(res)
    })
  },
  updateBrokerSymbolStatus(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerSymbolStatus(data)
      resolve(res)
    })
  },
  updateBrokerConfigStatus(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerConfigStatus(data)
      resolve(res)
    })
  },
  updateBrokerConfig(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerConfig(data)
      resolve(res)
    })
  },
  addBrokerConfig(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addBrokerConfig(data)
      resolve(res)
    })
  },
  addBrokerSymbol(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addBrokerSymbol(data)
      resolve(res)
    })
  },
  updateBrokerSymbol(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerSymbol(data)
      resolve(res)
    })
  },
  getListSymbol (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListSymbol()
      ctx.dispatch('setListSymbol', res.data)
      resolve(res)
    })
  },
  getListCurrencyFilter (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListCurrencyFilter()
      ctx.dispatch('setListCurrencyFilter', res.data)
      resolve(res)
    })
  },
  getListFeeType (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListFeeType()
      ctx.dispatch('setListFeeType', res.data)
      resolve(res)
    })
  },
  addSymbol(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addSymbol(data)
      resolve(res)
    })
  },
  getListBrokerWithdrawLevel (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListBrokerWithdrawLevel(params)
      ctx.dispatch('setListBrokerWithdrawLevel', res.data || {})
      resolve(res.data)
    })
  },
  updateBrokerWithdrawLevel(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerWithdrawLevel(data)
      resolve(res)
    })
  },

  // Broker Account Config
  getBrokersAccount (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getBrokersAccount(params)
      let result = res.data
      ctx.dispatch('setBrokersAccount', result)
      resolve(result)
    })
  },
  addBrokerAccount(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addBrokerAccount(data)
      resolve(res)
    })
  },
  updateBrokerAccount(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerAccount(data)
      resolve(res)
    })
  },

  // Broker Wallet Config
  getBrokersWallet (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getBrokersWallet(params)
      let result = res.data
      ctx.dispatch('setBrokersWallet', result)
      resolve(result)
    })
  },
  addBrokerWallet(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addBrokerWallet(data)
      resolve(res)
    })
  },
  updateBrokerWallet(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerWallet(data)
      resolve(res)
    })
  },
  getListBrokerCurrencyExchangeUsdt (ctx, params) {
    return new Promise(async resolve => {
      var res = await API.getListBrokerCurrencyExchangeUsdt(params)
      let listBrokerCurrencyExchangeUsdt = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setListBrokerCurrencyExchangeUsdt', listBrokerCurrencyExchangeUsdt)
      resolve(listBrokerCurrencyExchangeUsdt)
    })
  },
  addBrokerCurrencyExchangeUsdt(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.addBrokerCurrencyExchangeUsdt(data)
      resolve(res)
    })
  },
  updateBrokerCurrencyExchangeUsdt(_, data) {
    return new Promise(async resolve => {
      let res = await BrokerService.updateBrokerCurrencyExchangeUsdt(data)
      resolve(res)
    })
  },
}

export default { actions }
