<template>
  <div class="login-form-wrap">
    <form action="#" @submit.prevent="login()" :class="{'loading' : loading}">
    <!-- <form action="#" @submit.prevent="showRecaptcha" :class="{'loading' : loading}"> -->
      <div v-if="loading" class="ball-clip-rotate loading-inner">
        <div></div>
      </div>
      <div class="fields">
        <h2>{{$t('TEXT_WELCOME')}}</h2>
        <div class="field">
          <div class="input-wrap">
            <div class="icon">
              <i class="pe-7s-user"></i>
            </div>
            <input
              name="email"
              v-model="loginForm.email"
              :placeholder="$t('TEXT_EMAIL_ADDRESS')"
              type="text"
              maxlength="50"
              required
            >
          </div>
        </div>
        <div class="field">
          <div class="input-wrap">
            <div class="icon">
              <i class="pe-7s-lock"></i>
            </div>
            <input
              name="password"
              v-model="loginForm.password"
              maxlength="40"
              type="password"
              required
              :placeholder="$t('PLACE_PASSWORD')"
            >
          </div>
        </div>
        <div class="field">
          <button @mousemove="mouseOver" ref="submit" class="btn btn-primary btn-shadow">
            <span class="btn-text">{{$t('TEXT_SIGN_IN')}}</span>
            <span class="btn-hover-effect" :style="{left: hoverX+'px'}"></span>
          </button>
        </div>
      </div>
    </form>
    <el-dialog
      v-if="isShowRecaptcha"
      :visible.sync="isShowRecaptcha"
      :closeOnClickModal="false"
      class="tiny p-30"
      :title="$t('ACTIVITY_VERIFY')"
    >
      <div ref="popupInner" class="popup-inner">
        <div id="recaptcha"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'login',
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      loading: false,
      pwdType: 'password',
      hoverX: 0,
      isShowRecaptcha: false
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    routes () {
      return this.$store.getters.routes
    },
    filterredRoutes () {
      return this.routes.filter(temp => {
        return !temp.hidden && temp.children
      })
    }
  },
  methods: {
    aftterLogin (rs) {
      if (!rs) {
        return
      } else {
        this.$router.push({ path: '/dashboard' })
      }
    },
    mouseOver(e) {
      this.hoverX = e.x - (this.$refs.submit.offsetParent.offsetLeft + this.$refs.submit.offsetLeft)
    },
    async login () {
    // async submit (reCaptcha) {
      this.loading = true
      let login = await this.$store.dispatch('login', this.loginForm)
      this.loading = false
      this.aftterLogin(login)
    }
  }
}
</script>
