import LangService from '@/api/LangService'
import _ from 'lodash'
var actions = {
  getLangs (ctx, params) {
    return new Promise(async resolve => {
      let res = await LangService.getLangs() || []
      let langs = res.data
      let countriesCode = Object.keys(_.groupBy(langs, 'country'))
      ctx.dispatch('setCountriesCode', countriesCode)
      langs = _.groupBy(langs, 'key')
      langs = _.map(langs, temp => {
        return Object.assign(_.groupBy(temp, 'country'), { key: temp[0].key })
      })
      ctx.dispatch('setLangs', langs)
      resolve(langs)
    })
  },
  removeLangKey (__, key) {
    return new Promise(async resolve => {
      let res = await LangService.removeLangKey(key)
      resolve(res)
    })
  },
  updateLangKey (__, data) {
    return new Promise(async resolve => {
      let res = await LangService.updateLangKey(data)
      resolve(res)
    })
  },
  createLangKey (__, data) {
    return new Promise(async resolve => {
      let res = await LangService.createLangKey(data)
      resolve(res)
    })
  },
  exportLang (__, key) {
    return new Promise(async resolve => {
      let res = await LangService.exportLang(key)
      resolve(res)
    })
  },
  publishLang (__, key) {
    return new Promise(async resolve => {
      let res = await LangService.publish(key)
      resolve(res)
    })
  }
}

export default { actions }
