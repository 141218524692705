<template>
  <div class="organization-wrap">
    <div class="organization-box">
      <h4 class="title">Organization chart</h4>
      <ul class="badges">
        <li v-for="i in activeLevelColors" :key="i"><div class="square" :style="`background-color: ${colors[i]};`"></div><div class="name">{{i}}</div></li>
      </ul>
      <div class="binary-tree-wrap">
        <div class="chart" id="binary-tree"/>
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANT from '@/config/Constant'
const go = window.go
export default {
  data () {
    return {
      colors: CONSTANT.COLORS_LEVEL,
      myDiagram: {},
      treeData: {},
      treeDataArr: [],
      parrentsCodes: {},
      currentFloor: 7 // When be started, floorInit = currentFloor
    }
  },
  computed: {
    levelColors () {
      return Object.keys(this.colors)
    },
    activeLevelColors () {
      return this.levelColors.filter(temp => temp !== 'R0')
    },
    showLoading () {
      return this.$store.getters.showLoading
    },
    user () {
      return this.$store.getters.user
    },
    binaryData () {
      return this.$store.getters.binaryData
    },
    historyData () {
      return this.$store.getters.historyData
    },
    searchUserCode () {
      return this.$store.getters.searchUserCode
    },
    resetZoom () {
      return this.$store.getters.resetZoom
    },
    simpleChart () {
      return this.$store.getters.simpleChart
    }
  },
  watch: {
    resetZoom (val) {
      if (val) {
        this.myDiagram.commandHandler.zoomToFit()
        this.$store.dispatch('setResetZoom', false)
      }
    },
    binaryData (val) {
      if (!val || !val.length) return
      this.myDiagram.model = this.loadDataForDiagram(val)
    }
  },
  mounted () {
    this.initDiagram()
    const code = this.$route.query.code
    if (!code) return
    this.$store.dispatch('getUserAffTree', { code })
    this.myDiagram.model = this.loadDataForDiagram(this.binaryData)
    setTimeout(() => {
      // this.$store.dispatch('setLoadChart', false)
    }, 1000)
  },
  methods: {
    initDiagram () {
      var $ = go.GraphObject.make // for conciseness in defining templates
      this.myDiagram = $(go.Diagram, 'binary-tree', {
        maxSelectionCount: 1,
        initialAutoScale: go.Diagram.Uniform,
        initialDocumentSpot: go.Spot.TopCenter,
        initialViewportSpot: go.Spot.TopCenter,
        layout: $(go.TreeLayout, {
          comparer: go.LayoutVertex.smartComparer,
          treeStyle: go.TreeLayout.StyleLayered,
          arrangement: go.TreeLayout.ArrangementHorizontal,
          // properties for most of the tree:
          angle: 90,
          // layerSpacing: 80,
          // properties for the 'last parents':
          alternateAngle: 90,
          alternateLayerSpacing: 35,
          alternateAlignment: go.TreeLayout.AlignmentCenterSubtrees,
          alternateNodeSpacing: 20
        }),

        allowCopy: false,
        allowDelete: false,
        allowDragOut: false,
        allowDrop: false,
        allowInsert: false,
        allowMove: false,
        allowTextEdit: false,
        allowLink: false
      })

      var levelColors = CONSTANT.COLORS_LEVEL

      // override TreeLayout.commitNodes to also modify the background brush based on the tree depth level
      this.myDiagram.layout.commitNodes = () => {
        go.TreeLayout.prototype.commitNodes.call(this.myDiagram.layout) // do the standard behavior
        // then go through all of the vertexes and set their corresponding node's Shape.fill
        // to a brush dependent on the TreeVertex.level value
        this.myDiagram.layout.network.vertexes.each(function (v) {
          if (v.node) {
            var level = v.node.data.role
            var color = levelColors[level] || levelColors.USER
            var shape = v.node.findObject('SHAPE')
            // Background style
            if (shape) {
              shape.fill = $(go.Brush, 'Linear', {
                0: color,
                1: go.Brush.lightenBy(color, 0.05),
                start: go.Spot.Left,
                end: go.Spot.Right
              })
            }

            // if (shape) {
            //   shape.stroke = color
            //   shape.strokeWidth = 4
            // }
            // $(go.Shape, 'Square', {
            //   name: 'SHAPE',
            //   fill: 'white',
            //   stroke: CONSTANT.BASE_COLOR,
            //   // set the port properties:
            //   portId: '',
            //   cursor: 'pointer'
            // }),
          }
        })
      }

      var nodeDoubleClick = (e, obj) => {
        // var clicked = obj.part
        // const data = clicked.data
        // window.open(`${window.location.origin}/binary/organization-chart?code=${data.code}`, '_blank')
      }
      function textStyle () {
        return { font: '10pt  Segoe UI,sans-serif', stroke: 'white' }
      }

      function format (
        amount,
        decimalCount = 0,
        decimal = '.',
        thousands = ','
      ) {
        try {
          decimalCount = Math.abs(decimalCount)
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount

          const negativeSign = amount < 0 ? '-' : ''

          const i = parseInt(
            (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
          ).toString()
          const j = i.length > 3 ? i.length % 3 : 0
          return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
            (decimalCount
              ? decimal +
                Math.abs(amount - i)
                  .toFixed(decimalCount)
                  .slice(2)
              : '')
          )
        } catch (e) {
          console.log(e)
        }
      }

      // define the Node template
      this.myDiagram.nodeTemplate = $(
        go.Node,
        'Auto',
        { doubleClick: nodeDoubleClick },
        // for sorting, have the Node.text be the data.name
        new go.Binding('text', 'name'),
        // bind the Part.layerName to control the Node's layer depending on whether it isSelected
        new go.Binding('layerName', 'isSelected', function (sel) {
          return sel ? 'Foreground' : ''
        }).ofObject(),
        new go.Binding('isTreeExpanded', 'level', function (sel) {
          return sel < 6
        }),
        // define the node's outer shape
        $(go.Shape, 'Square', {
          name: 'SHAPE',
          fill: 'white',
          stroke: CONSTANT.BASE_COLOR,
          // set the port properties:
          portId: '',
          cursor: 'pointer'
        }),
        $(
          go.Panel,
          'Horizontal',
          // define the panel where the text will appear
          $(
            go.Panel,
            'Table',
            {
              maxSize: new go.Size(276, 999),
              margin: new go.Margin(10, 10, 0, 16),
              defaultAlignment: go.Spot.Left
            },
            $(go.RowColumnDefinition, { column: 5, width: 80 }),
            // $(
            //   go.Picture,
            //   { desiredSize: new go.Size(100, 40) },
            //   {
            //     row: 0,
            //     column: 0,
            //     columnSpan: 5,
            //     alignment: go.Spot.Center
            //   },
            //   new go.Binding('source', 'userCrowdFund', key => {
            //     console.log('abc')
            //     if (key) {
            //       console.log(key)
            //       return '/assets/images/vip-text.png'
            //     }
            //   })
            // ),
            // star image
            $(go.RowColumnDefinition, { column: 5, width: 80 }),
            $(
              go.Picture,
              {
                desiredSize: new go.Size(20, 20)
              },
              {
                row: 0,
                column: 0,
                columnSpan: 5,
                alignment: go.Spot.Center,
                margin: new go.Margin(10, 0, 20, 0),
                height: 20
              },
              new go.Binding('source', 'userId', key => {
                return '/assets/images/user.png'
              })
            ),

            $(
              go.TextBlock,
              textStyle(),
              {
                row: 2,
                column: 2,
                columnSpan: 5,
                alignment: go.Spot.Center,
                margin: new go.Margin(0, 0, 10, 0),
                font: '12pt Segoe UI,sans-serif',
                isMultiline: false,
                minSize: new go.Size(200, 16),
                height: 40
              },
              new go.Binding('text', 'beautyAddress', key => {
                return key.toString()
              })
            ),
            $(go.TextBlock, 'Role: ', textStyle(), {
              row: 3,
              column: 0,
              height: 24
            }),
            $(
              go.TextBlock,
              textStyle(),
              {
                row: 3,
                column: 1,
                columnSpan: 4,
                alignment: go.Spot.Right,
                minSize: new go.Size(10, 14),
                margin: new go.Margin(0, 0, 0, 3),
                height: 24
              },
              new go.Binding('text', 'role').makeTwoWay()
            ),
            $(go.TextBlock, 'Code: ', textStyle(), {
              row: 4,
              column: 0,
              height: 24
            }),
            $(
              go.TextBlock,
              textStyle(),
              {
                row: 4,
                column: 1,
                columnSpan: 4,
                alignment: go.Spot.Right,
                minSize: new go.Size(10, 14),
                margin: new go.Margin(0, 0, 0, 3),
                height: 24
              },
              new go.Binding('text', 'key').makeTwoWay()
            ),
            // $(go.TextBlock, 'Parent Code: ', textStyle(), { row: 5, column: 0, height: 24 }),
            // $(
            //   go.TextBlock,
            //   textStyle(),
            //   {
            //     row: 5,
            //     column: 1,
            //     columnSpan: 4,
            //     alignment: go.Spot.Right,
            //     height: 24
            //   },
            //   new go.Binding('text', 'parent', v => {
            //     return v
            //   })
            // ),
            // $(go.TextBlock, 'Refferal Code: ', textStyle(), { row: 6, column: 0, height: 24 }),
            // $(
            //   go.TextBlock,
            //   textStyle(),
            //   {
            //     row: 6,
            //     column: 1,
            //     columnSpan: 4,
            //     alignment: go.Spot.Right,
            //     height: 24
            //   },
            //   new go.Binding('text', 'refCode', v => {
            //     return v
            //   })
            // ),
            $(go.TextBlock, 'Total sale: ', textStyle(), { row: 5, column: 0, height: 24 }),
            $(
              go.TextBlock,
              textStyle(),
              {
                row: 5,
                column: 1,
                columnSpan: 4,
                alignment: go.Spot.Right,
                height: 24
              },
              new go.Binding('text', 'totalSale', v => {
                return format(v)
              })
            ),
            $(
              go.TextBlock,
              textStyle(),
              {
                row: 6,
                column: 0,
                columnSpan: 5,
                font: 'italic 9pt sans-serif',
                wrap: go.TextBlock.WrapFit,
                minSize: new go.Size(10, 14)
              },
              ''
            )
          )
        ),
        $('TreeExpanderButton',
          { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
          { visible: true }
        )
      )
      // define the Link template
      this.myDiagram.linkTemplate = $(
        go.Link,
        {
          routing: go.Link.Normal,
          corner: 5,
          selectable: false,
          relinkableFrom: false,
          relinkableTo: false
        },
        $(go.Shape, { strokeWidth: 1, stroke: CONSTANT.BASE_COLOR })
      )
      this.myDiagram.addDiagramListener('TreeExpanded', (e) => {
        const dataNode = e.subject.n[0].data
        Object.keys(this.parrentsCodes).forEach(key => {
          if (this.parrentsCodes[key].includes(dataNode.code)) {
            if (this.currentFloor - key <= 2) {
              if (this.currentFloor < Object.keys(this.treeData).length) {
                this.myDiagram.model.addNodeDataCollection(this.treeData[++this.currentFloor])
              }
            }
          }
        })
      })
    },
    loadDataForDiagram (binaryData) {
      let temp = []
      this.organizationTree([...binaryData].reverse())
      for (let i = 1; i <= this.currentFloor; i++) {
        if (this.treeData[i]) {
          temp = temp.concat(this.treeData[i])
        }
      }
      var data = {
        class: 'TreeModel',
        nodeDataArray: temp
      }
      return go.Model.fromJson(data)
    },
    organizationTree (data) {
      if (data.length) {
        this.treeData[1] = [data[data.length - 1]]
        this.parrentsCodes[1] = []
        data.splice(data.length - 1, 1)
        this.loopTree(data, 2)
      }
    },
    loopTree (data, index) {
      if (data.length <= 0) {
        return
      }
      this.treeData[index] = []
      this.parrentsCodes[index] = []
      this.treeData[index - 1].forEach((item) => {
        this.parrentsCodes[index].push(item.code)
      })
      for (let i = data.length - 1; i >= 0; i--) {
        if (this.parrentsCodes[index].includes(data[i].parentCode)) {
          this.treeData[index].push(data[i])
          data.splice(i, 1)
        }
      }
      if (data.length > 0) {
        this.loopTree(data, index + 1)
      } else {
        Object.keys(this.treeData).forEach((key) => {
          this.treeDataArr = this.treeDataArr.concat(this.treeData[key])
        })
      }
    }
  }
}
</script>
