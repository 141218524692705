var state = {
  users: [],
  kycUsers: [],
  user: {},
  admins: [],
  admin: {},
  roles: {},
  activities: {},
  userOverview: {},
  userDetail: [],
  binaryData: [],
  adminRole: '',
  usersInfo: [],
  transactionHistory: [],
  buyHistory: [],
  userComsHistory: {},
  commissionHistory: [],
  commissionAleoHistory: [],
  commissionBrandHistory: [],
  buyHistoryTransaction: []
}

var getters = {
  users: state => state.users,
  kycUsers: state => state.kycUsers,
  user: state => state.user,
  roles: state => state.roles,
  admins: state => state.admins,
  admin: state => state.admin,
  activities: state => state.activities,
  userDetail: state => state.userDetail,
  userOverview: state => state.userOverview,
  binaryData: state => state.binaryData,
  adminRole: state => state.adminRole,
  usersInfo: state => state.usersInfo,
  transactionHistory: state => state.transactionHistory,
  buyHistory: state => state.buyHistory,
  userComsHistory: state => state.userComsHistory,
  commissionBrandHistory: state => state.commissionBrandHistory,
  commissionHistory: state => state.commissionHistory,
  commissionAleoHistory: state => state.commissionAleoHistory,
  buyHistoryTransaction: state => state.buyHistoryTransaction
}

var mutations = {
  SET_COMMISSION_HISTORY: (state, data) => {
    state.commissionHistory = data
  },
  SET_COMMISSION_ALEO_HISTORY: (state, data) => {
    state.commissionAleoHistory = data
  },
  SET_COMMISSION_BRAND_HISTORY: (state, data) => {
    state.commissionBrandHistory = data
  },
  SET_USER_COMS_OVERVIEW: (state, data) => { state.userComsHistory = data },
  SET_BUY_HISTORY: (state, data) => { state.buyHistory = data },
  SET_TRANSACTION_HISTORY: (state, data) => { state.transactionHistory = data },
  SET_BINARY_DATA: (state, data) => {
    state.binaryData = data
  },
  SET_USERS: (state, data) => {state.users = data},
  SET_KYC_USERS: (state, data) => {state.kycUsers = data},
  SET_USER: (state, data) => { state.user = data },
  SET_ROLES: (state, data) => { state.roles = data },
  SET_ADMINS: (state, data) => {state.admins = data},
  SET_ADMIN: (state, data) => {state.admin = data},
  SET_ACTIVITIES: (state, data) => {state.activities = data},
  SET_USERDETAIL: (state, data) => {state.userDetail = data},
  SET_USER_OVERVIEW: (state, data) => { state.userOverview = data },
  SET_ADMIN_ROLE: (state, data) => {state.adminRole = data},
  SET_USER_INFOS: (state, data) => { state.usersInfo = data },
  SET_BUY_HISTORY_TRANSACTION: (state, data) => { state.buyHistoryTransaction = data }
}

export default { state, getters, mutations }
