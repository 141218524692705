<template>
  <div :key="count">
    <apexchart width="100%" type="area" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      count: 0,
      options: {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [],
          labels: {
            hideOverlappingLabels: true,
            formatter: (val) => {
              return this.$moment(new Date(val)).format("DD MMM");
            },
            trim: true
          }
        }
      },
      series: []
    }
  },
  computed: {
    userOverview () {
      return this.$store.getters.userOverview
    }
  },
  watch: {
    userOverview (val) {
      if (val) {
        this.options = {
          chart: {
            id: 'vuechart-example',
            toolbar: {
              show: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            categories: val.categories,
            labels: {
              hideOverlappingLabels: true,
              formatter: (val) => {
                let data = this.$moment.unix(val/1000).format('YYYY-MM-DD')
                return data
              },
              trim: true
            }
          }
        }
        this.series = val.series
        this.count++
      }
    }
  }
}
</script>
