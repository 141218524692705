
import CONFIG from '@/config/Config'
import { Get, Post } from '@/utils/RequestService'

var apiUrl = CONFIG.API_POINT
const apiV2Url = CONFIG.API_POINT_V2

var service = {
  getUserInfo: () => Get(`${apiUrl}/admin/get-info`),
  getUsers: (page, params) => Post(`${apiUrl}admin/investors/${page}/20`, params),
  getKycUsers: params => Get(`${apiUrl}/kyc/list-kyc-level`, params),
  getRoles: params => Get(`${apiUrl}/role/list-roles`, params),
  getAdmins: params => Get(`${apiUrl}/admin/list-admin`, params),
  getAdmin: params => Get(`${apiUrl}/admin/${params.id}/get-detail-info`, params),
  updateAdministrator: params => Post(`${apiUrl}/admin/update-admin`, params),
  updateUserStatus: data => Post(`${apiUrl}/user/update-user-status`, data),
  changeEmail: data => Post(`${apiUrl}admin/change-email`, data),
  getUserAff: (code, level) => Get(`${apiUrl}admin/aff-member/${code}/${level}`),
  getUserKyc: (page, params) => Get(`${apiUrl}admin/kyc/${page}/20`, params),
  confirmKyc: data => Post(`${apiUrl}admin/confirm-kyc`, data),
  getUserAffTree: (code, level) => Get(`${apiUrl}admin/matrix-info/${code}/${level || ''}`),
  blockUser: data => Post(`${apiUrl}/user/block`, data),
  unblockUser: data => Post(`${apiUrl}/user/unblock`, data),
  transferInExchange: data => Post(`${apiUrl}/user/set-allow-transfer-in-exchange`, data),
  transferOutExchange: data => Post(`${apiUrl}/user/set-allow-transfer-out-exchange`, data),
  addSupportUser: data => Post(`${apiUrl}/user/create-supporter`, data),
  removeSupportUser: data => Post(`${apiUrl}/user/remove-supporter-role`, data),
  addAdministrator: data => Post(`${apiUrl}/admin/create-admin`, data),
  removeAdministrator: data => Post(`${apiUrl}/admin/deleted/${data.id}`, data),
  getActivities: data => Get(`${apiUrl}/activities/list-admin-activity`, data),
  getUserDetail: data => Get(`${apiUrl}/user/get-detail-info`, data),
  getUserOverview: params => Get(`${apiUrl}/user/count-statistic`, params),
  getAdminRole: () => Get(`${apiUrl}admin`),
  getUsersInfo: (page, params) => Get(`${apiV2Url}admin/balances/balance`, params),
  getTransactionHistory: (page, params) => Get(`${apiV2Url}admin/balances/transaction-history/${page}/20`, params),
  getUserBuyHistory: (page, params) => Get(`${apiV2Url}admin/users/trans-history/${page}/10`, params),
  getBuyHistory: (page, params) => Get(`${apiV2Url}admin/coms/mining-history/${page}/20`, params),
  getUserComsOverview: (page, params) => Get(`${apiV2Url}admin/users/user-overview`, params),
  getCommissionRefHistory: (page, params) => Get(`${apiV2Url}admin/coms/ref-history/${params.symbol}/${page}/20`, params),
  getCommissionAleoHistory: (page, params) => Get(`${apiV2Url}admin/coms/mining-history/${page}/20`, params),
  getCommissionBrandHistory: (page, params) => Get(`${apiV2Url}admin/coms/brand-history/${params.symbol}/${page}/20`, params),
  getBuyHistoryTransaction: (page, params) => Get(`${apiUrl}admin/trans-history/${page}/20`, params),
}
export default service
