import Vue from 'vue'
import 'normalize.css/normalize.css'// A modern alternative to CSS resets

import '@/assets/base.scss'

import App from './App'
import router from './router'
import store from './store'

// language
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

import i18n from '@/plugin/i18n' // Internationalization
import google from '@/plugin/google'
// Plugin
import RouterInterceptor from '@/plugin/router-interceptor'
import VueClipboard from 'vue-clipboard2'

// configure language
locale.use(lang)
Vue.prototype.$google = google

import {
  Dialog,
  Icon,
  Pagination,
  Notification,
  MessageBox,
  Button,
  Tag,
  Switch,
  Image,
  DatePicker,
  Input,
  Tooltip,
  Select,
  Option,
  Tabs,
  TabPane,
} from 'element-ui'

Vue.use(Dialog)
Vue.use(Icon)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Tag)
Vue.use(Switch)
Vue.use(Image)
Vue.use(DatePicker)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Input)
Vue.use(Tooltip)
Vue.use(Select)
Vue.use(Option)
Vue.use(VueClipboard)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification

Vue.use(require('vue-moment'))
Vue.use(RouterInterceptor)

Vue.config.productionTip = false

Vue.filter('statusText', function (value) {
  if (value === 8) {
    return '<span class="w-90 el-tag el-tag--danger el-tag--light">DELETED</span>'
  } else if (value === 9) {
    return '<span class="w-90 el-tag el-tag--warning el-tag--light">LOCKED</span>'
  } else if (value === 11) {
    return '<span class="w-90 el-tag el-tag--success el-tag--light">ACTIVATED</span>'
  } else {
    return '<span class="w-90 el-tag el-tag--info el-tag--light">INACTIVATED</span>'
  }
})
Vue.filter('statusAdminText', function (value) {
  if (value === 8) {
    return '<span class="w-90 el-tag el-tag--danger el-tag--light">DELETED</span>'
  } else if (value === 9) {
    return '<span class="w-90 el-tag el-tag--warning el-tag--light">LOCKED</span>'
  } else if (value === 11) {
    return '<span class="w-90 el-tag el-tag--success el-tag--light">ACTIVATED</span>'
  } else {
    return '<span class="w-90 el-tag el-tag--info el-tag--light">INACTIVATED</span>'
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
