var state = {
  wallets: [],
  transactions: [],
  transactionsStatus: [],
  transactionsType: [],
  tranfers: [],
  transferStatus: [],
  transferType: [],
  orderStatus: [],
  balanceOverView: {},
  symbols: [],
  listWalletUserApiKey: [],
  listWallets: [],
  tokenRemaining: [],
  transactionConfirmStatus: []
}

var getters = {
  wallets: state => state.wallets,
  transactions: state => state.transactions,
  tranfers: state => state.tranfers,
  orderStatus: state => state.orderStatus,
  balanceOverView: state => state.balanceOverView,
  tokenRemaining: state => state.tokenRemaining,
  transactionsStatus: state => state.transactionsStatus,
  transactionsType: state => state.transactionsType,
  transferType: state => state.transferType,
  transferStatus: state => state.transferStatus,
  symbols: state => state.symbols,
  listWalletUserApiKey: state => state.listWalletUserApiKey,
  listWallets: state => state.listWallets,
  transactionConfirmStatus: state => state.transactionConfirmStatus
}

var mutations = {
  SET_TRANSACTION_CONFIRM_STATUS: (state, data) => { state.transactionConfirmStatus = data },
  SET_WALLETS: (state, data) => {state.wallets = data},
  SET_TRANSACTIONS: (state, data) => { state.transactions = data },
  SET_TRANFERS: (state, data) => { state.tranfers = data },
  SET_ORDER_STATUS: (state, data) => { state.orderStatus = data },
  SET_BALANCE_OVER_VIEW: (state, data) => { 
    state.balanceOverView = data 
  },
  SET_TOKEN_REMAINING: (state, data) => { state.tokenRemaining = data },
  SET_TRANSACTION_STATUS: (state, data) => { state.transactionsStatus = data },
  SET_TRANSACTION_TYPE: (state, data) => { state.transactionsType = data },
  SET_TRANSFER_STATUS: (state, data) => { state.transferStatus = data },
  SET_TRANSFER_TYPE: (state, data) => { state.transferType = data },
  SET_SYMBOLS: (state, data) => { state.symbols = data },
  SET_LIST_WALLET_USER_API_KEY: (state, data) => { state.listWalletUserApiKey = data },
  SET_LIST_WALLETS: (state, data) => { state.listWallets = data }
}

export default { state, getters, mutations }
