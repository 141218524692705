var actions = {
  setLangs ({ commit }, data) {
    commit('SET_LANGS', data)
  },
  setCountriesCode ({ commit }, data) {
    commit('SET_COUNTRIES_CODE', data)
  }
}

export default { actions }
