var state = {
  langs: [],
  countriesCode: []
}

var getters = {
  langs: state => state.langs,
  countriesCode: state =>state.countriesCode
}

var mutations = {
  SET_LANGS: (state, data) => { state.langs = data },
  SET_COUNTRIES_CODE: (state, data) => { state.countriesCode = data }
}

export default { state, getters, mutations }
