/* eslint-disable no-unused-expressions */
import API from '@/api/AuthService'
import LocalStorage from '@/utils/LocalStorage'
import CONSTANT from '@/config/Constant'
import AuthService from '@/api/AuthService'
import router from '@/router'
var actions = {
  refreshToken (ctx) {
    return new Promise(async resolve => {
      let tokenObj = LocalStorage.getToken() || {}
      let isValid = tokenObj && tokenObj.token && tokenObj.refreshToken
      if (!isValid) {
        ctx.dispatch('setUser', {})
        ctx.dispatch('setJwt', null)
        ctx.dispatch('setLoggedIn', false)
        LocalStorage.removeToken()
        LocalStorage.removeRemember()
        LocalStorage.removeItem(CONSTANT.IS_LOGGED)
        router.push({ name: 'login' })
        resolve(true)
        return
      }
      var res = await AuthService.refreshToken(tokenObj)
      var jwt = res.data || false
      if (res.status) {
        ctx.dispatch('setJwt', jwt)
      } else {
        ctx.dispatch('setUser', {})
        ctx.dispatch('setJwt', null)
        ctx.dispatch('setLoggedIn', false)
        LocalStorage.removeToken()
        LocalStorage.removeRemember()
        LocalStorage.removeItem(CONSTANT.IS_LOGGED)
        router.push({ name: 'login' })
      }
      resolve(jwt)
    })
  },
  login (ctx, loginForm) {
    return new Promise(async resolve => {
      var res = await API.login(loginForm)
      res.status && res.data
        ? (ctx.dispatch('setJwt', res.data),
          ctx.dispatch('setLoggedIn', true),
          resolve(res.data))
        : resolve(false)
    })
  },
  logOut (ctx) {
    return new Promise(async resolve => {
      // var res = await AuthService.logout()
      // res && res.status ? resolve(true) : resolve(false)
      ctx.dispatch('setUser', {}),
      ctx.dispatch('setJwt', null),
      ctx.dispatch('setLoggedIn', false),
      LocalStorage.removeToken(),
      LocalStorage.removeRemember(),
      LocalStorage.removeItem(CONSTANT.IS_LOGGED)
      router.push({ name: 'Login' })
    })
  },
  forceLogout (ctx) {
    ctx.dispatch('setUser', {})
    ctx.dispatch('setJwt', null)
    ctx.dispatch('setLoggedIn', false)
    LocalStorage.removeToken()
    LocalStorage.removeRemember()
    LocalStorage.removeItem(CONSTANT.IS_LOGGED)
    router.push({ name: 'Login' })
  },
  changePassword (__, data) {
    return new Promise(async resolve => {
      var token = await this._vm.$google.getCaptcha('change-password')
      token && (data.reCaptcha = token)
      let res = await API.changePassword(data)
      resolve(res)
    })
  }
}

export default { actions }
