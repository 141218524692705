import UtilService from '../../../utils/UtilService'
import WalletService from '@/api/WalletService'

var actions = {
  getWallets (ctx, params) {
    return new Promise(async resolve => {
      var res = await WalletService.getWallets(params)
      let wallets = res.data
      ctx.dispatch('setPagination', res.pagination)
      ctx.dispatch('setWallets', wallets)
      resolve(wallets)
    })
  },
  confirmTransactionWithdraw (ctx, data) {
    return new Promise(async resolve => {
      let res = await WalletService.confirmTransactionWithdraw(data)
      resolve(res)
    })
  },
  getTransactions (ctx, params) {
    return new Promise(async resolve => {
      var res = await WalletService.getTransactions(params.page, params)
      let transactions = res.data
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformTimes(transactions)
      result = UtilService.transformHistory(result)
      ctx.dispatch('setTransactions', result)
      resolve(result)
    })
  },
  getTranfers (ctx, params) {
    return new Promise(async resolve => {
      var res = await WalletService.getTranfers(params)
      let tranfers = res.data
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformTimes(tranfers)
      ctx.dispatch('setTranfers', result)
      resolve(result)
    })
  },
  getSymbols (ctx, params) {
    return new Promise(async resolve => {
      var res = await WalletService.getSymbols(params)
      let symbols = res.data
      ctx.dispatch('setSymbols', symbols)
      resolve(symbols)
    })
  },
  getOrderStatus (ctx) {
    return new Promise(async resolve => {
      var res = await WalletService.getOrderStatus()
      let orderStatus = res.data
      ctx.dispatch('setOrderStatus', orderStatus)
      resolve(orderStatus)
    })
  },
  getTransactionsType (ctx) {
    return new Promise(async resolve => {
      var res = await WalletService.getTransactionsType()
      let transactionsType = res.data
      ctx.dispatch('setTransactionsType', transactionsType)
      resolve(transactionsType)
    })
  },
  getTransactionConfirmStatus (ctx) {
    return new Promise(async resolve => {
      var res = await WalletService.getTransactionConfirmStatus()
      let transactionsStatus = res.data
      ctx.dispatch('setTransactionConfirmStatus', transactionsStatus)
      resolve(transactionsStatus)
    })
  },
  getTransactionByHash (ctx, hash) {
    return new Promise(async resolve => {
      var res = await WalletService.getTransactionByHash(hash)
      let transactionsStatus = res.status
      resolve(transactionsStatus)
    })
  },
  getTransactionsStatus (ctx) {
    return new Promise(async resolve => {
      var res = await WalletService.getTransactionsStatus()
      let transactionsStatus = res.data
      ctx.dispatch('setTransactionsStatus', transactionsStatus)
      resolve(transactionsStatus)
    })
  },
  getTransferStatus (ctx) {
    return new Promise(async resolve => {
      var res = await WalletService.getTransferStatus()
      let transferStatus = res.data
      ctx.dispatch('setTransferStatus', transferStatus)
      resolve(transferStatus)
    })
  },
  getTransferType (ctx) {
    return new Promise(async resolve => {
      var res = await WalletService.getTransferType()
      let transferType = res.data
      ctx.dispatch('setTransferType', transferType)
      resolve(transferType)
    })
  },
  async getBalanceOverView (ctx) {
    var res = await WalletService.getBalanceOverView()
    let balanceOverView = UtilService.transformBalancesOverview(res.data || {})
    ctx.dispatch('setBalanceOverView', res.data)
  },

  getTokenRemanining (ctx, params) {
    return new Promise(async resolve => {
      var res = await WalletService.getTokenRemanining(params)
      let balanceOverView = res.data
      ctx.dispatch('setTokenRemaining', balanceOverView)
      resolve(balanceOverView)
    })
  },

  // Wallet User Api Key
  getListWalletUserApiKey (ctx, params) {
    return new Promise(async resolve => {
      var res = await WalletService.getListWalletUserApiKey(params)
      let result = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setListWalletUserApiKey', result)
      resolve(result)
    })
  },
  addWalletUserApiKey(_, data) {
    return new Promise(async resolve => {
      let res = await WalletService.addWalletUserApiKey(data)
      resolve(res)
    })
  },
  updateWalletUserApiKey(_, data) {
    return new Promise(async resolve => {
      let res = await WalletService.updateWalletUserApiKey(data)
      resolve(res)
    })
  },

  //Wallet
  getListWallets (ctx, params) {
    return new Promise(async resolve => {
      var res = await WalletService.getListWallets(params)
      let result = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setListWallets', result)
      ctx.dispatch('setPagination', res.pagination)
      resolve(result)
    })
  },
}

export default { actions }
