import LocalStorage from '@/utils/LocalStorage'
import CONSTANT from '@/config/Constant'

var state = {
  usersKyc: []
}

var getters = {
  usersKyc: state => state.usersKyc
}

var mutations = {
  SET_USERS_KYC: (state, users) => { state.usersKyc = users }
}

export default { state, getters, mutations }
