import {commit} from "lodash/seq";

var actions = {
  setUsers ({ commit }, data) {
    commit('SET_USERS', data)
  },
  setKycUsers ({ commit }, data) {
    commit('SET_KYC_USERS', data)
  },
  setUserInfos ({ commit }, data) {
    commit('SET_USER_INFOS', data)
  },
  setTransactionHistory ({ commit }, data) {
    commit('SET_TRANSACTION_HISTORY', data)
  },
  setBuyHistory ({ commit }, data) {
    commit('SET_BUY_HISTORY', data)
  },
  setUserComsOverviewHistory ({ commit }, data) {
    commit('SET_USER_COMS_OVERVIEW', data)
  },
  setRoles({ commit }, data) {
    commit('SET_ROLES', data)
  },
  setUser ({ commit }, data) {
    commit('SET_USER', data)
  },
  setAdmins ({ commit }, data) {
    commit('SET_ADMINS', data)
  },
  setAdmin ({ commit }, data) {
    commit('SET_ADMIN', data)
  },
  setActivities ({ commit }, data) {
    commit('SET_ACTIVITIES', data)
  },
  setUserDetail ({ commit }, data) {
    commit('SET_USERDETAIL', data)
  },
  setUserOverview({ commit }, data) {
    commit('SET_USER_OVERVIEW', data)
  },
  setAdminRole({commit}, data) {
    commit('SET_ADMIN_ROLE', data)
  },
  setBuyHistoryTransaction ({ commit }, data) {
    commit('SET_BUY_HISTORY_TRANSACTION', data)
  }
}

export default { actions }
