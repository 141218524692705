import CONFIG from '@/config/Config'
import { Get, Post } from '@/utils/RequestService'

var apiUrl = CONFIG.API_POINT
var service = {
  getConfig: () => Get(`${apiUrl}/public/system-config`),
  getGoogleKey: () => Get(`${apiUrl}/user/new-google-key`),
  getRate: (url) => Get(url),
  getRateAleo: (symbol) => Get(`${apiUrl}admin/hash-rate-currency?currency=${symbol}`),
  getRateAleoHistory: (data) => Get(`${apiUrl}admin/history-hash-rate/${data.page}/50?currency=${data.currency}`),
  updateRateAleo: params => Post(`${apiUrl}admin/update-hash-rate`, params),
}

export default service
