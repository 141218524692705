
import CONFIG from '@/config/Config'
import { Get, Post } from '@/utils/RequestService'

var apiUrl = CONFIG.API_POINT
var service = {
  getLangs: () => Get(`${apiUrl}/lang/get-list`),
  removeLangKey: langKey => Post(`${apiUrl}/lang/remove/${langKey}`),
  updateLangKey: data => Post(`${apiUrl}/lang/update`, data),
  createLangKey: data => Post(`${apiUrl}/lang/add`, data),
  exportLang: langKey => Get(`${apiUrl}/lang/export/${langKey}`),
  publish: langKey => Post(`${apiUrl}/lang/upload-to-aws?countryCode=${langKey}`, {})
}

export default service
