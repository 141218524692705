import Vue from 'vue'

export default new Vue({
  methods: {
    mapMessage (statusId) {
      switch (statusId) {
        case -1: return {type: 'danger', text: 'Rejected'}
        case 0: return {type: 'danger', text: 'None'}
        case 1: return {type: 'warning', text: 'Pending'}
        case 2: return {type: 'success', text: 'Approved'}
        default: return ''
      }
    }
  }
})
