<template>
  <div class="dropdown">
    <button type="button" data-toggle="dropdown" class="p-0 btn btn-link" @click.stop="isOpened = !isOpened">
        <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                <span class="icon-wrapper-bg bg-focus"></span>
        <span class="language-icon opacity-8 flag large" :class="currentLanguage.toUpperCase()"></span>
        </span>
    </button>
    <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers dropdown-menu dropdown-menu-right"
         :class="{'show' : isOpened}"
         v-click-outside="closePopup"
         @click="isOpened = false"
    >
      <div class="dropdown-menu-header" @click.stop="">
        <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
          <div class="menu-header-content text-center text-white">
            <h6 class="menu-header-subtitle mt-0">{{$t('TEXT_CHOOSE_LANGUAGE')}}</h6>
          </div>
        </div>
      </div>
      <button v-for="(value, key, index) in listLanguage" :key="index" type="button" tabindex="0" class="dropdown-item" @click="currentLanguage = key">
        <span class="mr-3 opacity-8 flag large" :class="key.toUpperCase()"></span> {{value}}
      </button>
    </div>
  </div>
</template>
<script>
import ClickOutside from '@/directives/VueClickOutside'
import {loadLanguageAsync} from '@/plugin/i18n'
export default {
  data() {
    return {
      isOpened: false,
      listLanguage: {
        'gb': 'English',
        'jp': 'Japan',
        'vn': 'Viet Nam',
        'cn': 'China',
        'kr': 'Korea'
      },
      currentLanguage: 'gb' //en, ja, vi, zh, ko
    }
  },
  directives: {
    ClickOutside
  },
  watch: {
    currentLanguage(n, o) {
      this.updateLanguage()
    }
  },
  created() {
    // this.$store.dispatch('getLangs')
  },
  methods: {
    closePopup() {
      if (this.isOpened) this.isOpened = false
    },
    updateLanguage() {
      let temp = 'en'
      switch (this.currentLanguage) {
        case 'gb': temp = 'en'; break;
        case 'jp': temp = 'ja'; break;
        case 'vn': temp = 'vi'; break;
        case 'cn': temp = 'zh'; break;
        case 'kr': temp = 'ko'; break;
      }

      if (temp === this.$i18n.locale) {
        return
      }
      loadLanguageAsync(temp)
    }
  }
}
</script>
