<template>
  <div class="app-sidebar sidebar-shadow">
    <div class="app-header__logo">
        <div class="logo-src"></div>
        <div class="header__pane ml-auto">
            <div>
                <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar"
                  :class="{ 'is-active': !isOpenedMBSidebar }">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="app-header__mobile-menu">
        <div>
            <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav" @click="toggleMBSideBar">
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>
        </div>
    </div>
    <div class="app-header__menu">
        <span>
            <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                <span class="btn-icon-wrapper">
                    <i class="fa fa-ellipsis-v fa-w-6"></i>
                </span>
        </button>
        </span>
    </div>
    <div class="scrollbar-sidebar">
        <div class="app-sidebar__inner">
            <ul class="vertical-nav-menu">
              <template v-for="(route, index) in filterredRoutes">
                <div :key="index" v-if="route.meta.roles && route.meta.roles.indexOf(adminRole) !== -1">
                  <li class="app-sidebar__heading">{{$t(route.meta.title)}}</li>
                  <li v-for="(link, index) in route.children" :key="index">
                    <router-link
                        :to="{ name: link.name }">
                      <i :class="link.meta.icon">
                      </i>{{ $t(link.meta.title) }}
                    </router-link>
                  </li>
                </div>
              </template>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import UtilService from '@/utils/UtilService'
export default {
  computed: {
    routes () {
      return this.$router.options.routes
    },
    isCollapse () {
      return !this.sidebar.opened
    },
    filterredRoutes () {
      let result = this.routes.filter(temp => {
        return !temp.hidden && temp.children && temp.children.length && UtilService.checkRole(this.user, temp)
      })
      result.map(temp => {
        temp.children = temp.children.filter(link => this.isUserHasRole(link) && !link.hidden && UtilService.isShowRouter(this.user, link.name))
      })
      result = result.filter(temp => {
        return temp.children && temp.children.length
      })
      return result
    },
    user () {
      return this.$store.getters.user
    },
    isOpenedMBSidebar () {
      return this.$store.getters.isOpenedMBSidebar
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    adminRole () {
      return this.$store.getters.adminRole
    }
  },
  created () {
    this.$store.dispatch('getAdminRole')
  },
  methods: {
    toggleMBSideBar () {
      // this.$store.dispatch('toggleMBSideBar')
    },
    isUserHasRole (router) {
      if (router.meta && router.meta.roles) {
        return UtilService.isUserHasRole(this.user, router.meta.roles)
      }
      return  true
    }
  }
}
</script>
