<template>
  <div class="user-pie-chart" :key="count">
    <apexchart width="100%" type="donut" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      count: 0,
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        labels: [],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  computed: {
    userOverview () {
      return this.$store.getters.userOverview
    }
  },
  watch: {
    userOverview (val) {
      if (val) {
        this.chartOptions.labels = val.labels
        this.series = val.count
        this.count++
      }
    }
  }
}
</script>

