import API from '@/api/UserService'
import LocalStorage from '@/utils/LocalStorage'
import CONSTANT from '@/config/Constant'
import UserService from '@/api/UserService'
import UtilService from '../../../utils/UtilService'
import BigNumber from 'bignumber.js'

let actions = {
  getUsers (ctx, { params, page }) {
    return new Promise(async resolve => {
      let res = await API.getUsers(page, params)
      let user = res.data || []
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformUserInfo(user)
      result.forEach(temp => {
        temp.isActiveUser = temp.status === 'active'
      })
      ctx.dispatch('setUsers', result)
      resolve(result)
    })
  },
  getKycUsers (ctx, params) {
    return new Promise(async resolve => {
      let res = await API.getKycUsers(params)
      let user = res.data || []
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformTimes(user)
      ctx.dispatch('setKycUsers', result)
      resolve(result)
    })
  },
  getRoles (ctx, params) {
    return new Promise(async resolve => {
      let res = await API.getRoles(params)
      let data = res.data || []
      ctx.dispatch('setRoles', data)
    })
  },
  getAdmins(ctx, params) {
    return new Promise(async resolve => {
      let res = await API.getAdmins(params)
      let admins = res.data || []
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformTimes(admins)
      ctx.dispatch('setAdmins', result)
      resolve(result)
    })
  },
  getAdmin(ctx, params) {
    return new Promise(async resolve => {
      let res = await API.getAdmin(params)
      let admin = res.data || []
      ctx.dispatch('setAdmin', admin)
      resolve(admin)
    })
  },
  updateAdministrator(ctx, data) {
    return new Promise(async resolve => {
      let res = await API.updateAdministrator(data)
      resolve(res)
    })
  },
  getActivities(ctx, params) {
    return new Promise(async resolve => {
      let res = await API.getActivities(params)
      let activities = res.data || []
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformTimes(activities)
      ctx.dispatch('setActivities', result)
      resolve(result)
    })
  },
  getUserDetail(ctx, params) {
    return new Promise(async resolve => {
      let res = await API.getUserDetail(params)
      let userDetail = res.data || []
      let result = UtilService.transformTimeat(userDetail)
      ctx.dispatch('setUserDetail', result)
      resolve(result)
    })
  },
  getUserInfo (ctx) {
    return new Promise(async resolve => {
      let tokenObj = LocalStorage.getToken() || {}
      let isLoggedIn = LocalStorage.getItem(CONSTANT.IS_LOGGED) || false
      if (isLoggedIn && tokenObj.token) {
        let res = await API.getUserInfo()
        let user = res.data || null
        ctx.dispatch('setUser', user)
        resolve(user)
      } else { resolve(false) }
    })
  },
  updateUserStatus (__, data) {
    return new Promise(async resolve => {
      let res = await UserService.updateUserStatus(data)
      resolve(res)
    })
  },
  getUserAff (_, { code, level }) {
    return new Promise(async resolve => {
      let res = await UserService.getUserAff(code, level)
      resolve(res)
    })
  },
  confirmKyc (_, data) {
    return new Promise(async resolve => {
      let res = await UserService.confirmKyc(data)
      resolve(res)
    })
  },
  getUserKyc (ctx, { page, params }) {
    return new Promise(async resolve => {
      let res = await UserService.getUserKyc(page, params)
      res.data = UtilService.transformKyc(res.data)
      ctx.dispatch('setPagination', res.pagination)
      resolve(res)
    })
  },
  getUserAffTree ({ commit }, { code, level }) {
    return new Promise(async resolve => {
      let rs = await UserService.getUserAffTree(code, level)
      const data = rs.data || []
      for (let i = 0; i < data.length; i++) {
        const newTxt = data[i].username
        data[i].isIn = data[i].parentCode !== data[i].refCode
        data[i].beautyAddress = newTxt
        data[i].key = data[i].code
        data[i].parent = data[i].parentCode
        data[i].stt = i + 1
      }
      commit('SET_BINARY_DATA', data)
      resolve(true)
    }).catch(e => {
      commit('SET_BINARY_DATA', [])
      resolve(false)
    })
  },
  changeEmail (__, data) {
    return new Promise(async resolve => {
      let res = await UserService.changeEmail(data)
      resolve(res)
    })
  },
  blockUser(__, data) {
    return new Promise(async resolve => {
      let res = await UserService.blockUser(data)
      resolve(res)
    })
  },
  unblockUser(__, data) {
    return new Promise(async resolve => {
      let res = await UserService.unblockUser(data)
      resolve(res)
    })
  },
  transferInExchange(__, data) {
    return new Promise(async () => {
      await UserService.transferInExchange(data)
    })
  },
  transferOutExchange(__, data) {
    return new Promise(async () => {
      await UserService.transferOutExchange(data)
    })
  },
  addSupportUser(__, data) {
    return new Promise(async (resolve) => {
      let res = await UserService.addSupportUser(data)
      resolve(res)
    })
  },
  removeSupportUser(__, data) {
    return new Promise(async (resolve) => {
      let res = await UserService.removeSupportUser(data)
      resolve(res)
    })
  },
  addSupportRole(__, data) {
    return new Promise(async (resolve) => {
      let res = await UserService.addSupportRole(data)
      resolve(res)
    })
  },
  addAdministrator(__, data) {
    return new Promise(async (resolve) => {
      let res = await UserService.addAdministrator(data)
      resolve(res)
    })
  },
  removeAdministrator(__, data) {
    return new Promise(async (resolve) => {
      let res = await UserService.removeAdministrator(data)
      resolve(res)
    })
  },
  getUserOverview (ctx, params) {
    return new Promise(async resolve => {
      let res = await UserService.getUserOverview(params)
      let result = UtilService.transformUserOverview(res.data)
      ctx.dispatch('setUserOverview', result || {})
      resolve(res)
    })
  },
  getAdminRole (ctx) {
    return new Promise(async resolve => {
      let res = await UserService.getAdminRole()
      let role = ''
      if (res && res.data && res.data.role) {
        role = res.data.role
        ctx.dispatch('setAdminRole', role)
      }
      resolve(role)
    })
  },
  getUsersInfo (ctx, { page, params}) {
    return new Promise(async resolve => {
      let res = await UserService.getUsersInfo(page, params)
      let result = res.data || []
      ctx.dispatch('setUserInfos', result)
      resolve(result)
    })
  },
  getTransactionHistory (ctx, { page, params}) {
    return new Promise(async resolve => {
      let res = await UserService.getTransactionHistory(page, params)
      let history = res.data || []
      ctx.dispatch('setPagination', res.pagination)
      let result = UtilService.transformTimes(history)
      ctx.dispatch('setTransactionHistory', result)
      resolve(result)
    })
  },
  getBuyHistory (ctx, { page, params}) {
    return new Promise(async resolve => {
      let res = await UserService.getUserBuyHistory(page, params)
      let history = res.data || []
      ctx.dispatch('setPagination', res.pagination)
      history.map((item) => {
        if (item.createdAt) {
          item.createdAt = item.createdAt * 1000
        }
      })
      let result = UtilService.transformTimes(history)
      ctx.dispatch('setBuyHistory', result)
      resolve(result)
    })
  },
  getUserComsOverview (ctx, { page, params}) {
    return new Promise(async resolve => {
      let res = await UserService.getUserComsOverview(page, params)
      let history = res.data || []
      ctx.dispatch('setUserComsOverviewHistory', history)
      resolve(history)
    })
  },
  getCommissionRefHistory ({ commit }, param) {
    const page = param.page || 1
    UserService.getCommissionRefHistory(page, param.params).then(res => {
      const result = res.data || {}
      result.map((item) => {
        if (item.createdAt) {
          item.createdAt = item.createdAt * 1000
        }
      })
      commit('SET_COMMISSION_HISTORY', UtilService.transformTimes(result))
      this.dispatch('setPagination', res.pagination)
    })
  },
  getCommissionAleoHistory ({ commit }, param) {
    const page = param.page || 1
    UserService.getCommissionAleoHistory(page, param.params).then(res => {
      const result = res.data || {}
      result.map((item) => {
        if (item.createdAt) {
          item.createdAt = item.createdAt * 1000
        }
      })
      commit('SET_COMMISSION_ALEO_HISTORY', UtilService.transformTimes(result))
      this.dispatch('setPagination', res.pagination)
    })
  },
  getCommissionBrandHistory ({ commit }, param) {
    const page = param.page || 1
    UserService.getCommissionBrandHistory(page, param.params).then(res => {
      const result = res.data || {}
      result.map((item) => {
        if (item.createdAt) {
          item.createdAt = item.createdAt * 1000
        }
      })
      commit('SET_COMMISSION_BRAND_HISTORY', UtilService.transformTimes(result))
      this.dispatch('setPagination', res.pagination)
    })
  },

  // Get Transaction History Buy Package
  getBuyHistoryTransaction (ctx, params) {
    return new Promise( async resolve => {
      const page = params.page || 1
      if (!params.symbol) {
        delete params.symbol
      }
      var res = await API.getBuyHistoryTransaction(page, params)
      let result = res.data || []
      result.map((item) => {
        item.createdAt *= 1000
        item.amountUsdBeauty = new BigNumber(item.amountUsd).toFixed(2)
        item.amountPowerBeauty = new BigNumber(item.amountPower).toFixed(2)
        item.tokenBonusBeauty = new BigNumber(item.tokenBonus).toFixed(2)
        item.powerBonusBeauty = new BigNumber(item.powerBonus).toFixed(2)
        item.packageIdBeauty = item.packageId
        item.packageIdBeauty = item.packageId.substring(0, 5) + '...' + item.packageId.substring(item.packageId.length - 5, item.packageId.length )
      })
      result = UtilService.transformTimes(res.data || [])
      ctx.dispatch('setBuyHistoryTransaction', result)
      ctx.dispatch('setPagination', res.pagination)
      resolve(result)
    })
  }
}

export default { actions }
