<template>
  <div div class="no-data table-cell">
        <div class="el-table__empty-inner">
          <span class="el-table__empty-text">{{$t('TEXT_NO_DATA_TO_DISPLAY')}}</span>
        </div>
      </div>
</template>

<script>

export default {
  created () {
  }
}
</script>
