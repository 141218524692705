import LangAdminService from '@/api/LangAdminService'
import _ from 'lodash'
var actions = {
  getAdminLangs (ctx, params) {
    return new Promise(async resolve => {
      let res = await LangAdminService.getLangs() || []
      let langs = res.data
      let countriesCode = Object.keys(_.groupBy(langs, 'country'))
      ctx.dispatch('setCountriesCode', countriesCode)
      langs = _.groupBy(langs, 'key')
      langs = _.map(langs, temp => {
        return Object.assign(_.groupBy(temp, 'country'), { key: temp[0].key })
      })
      ctx.dispatch('setLangs', langs)
      resolve(langs)
    })
  },
  removeAdminLangKey (__, key) {
    return new Promise(async resolve => {
      let res = await LangAdminService.removeLangKey(key)
      resolve(res)
    })
  },
  updateAdminLangKey (__, data) {
    return new Promise(async resolve => {
      let res = await LangAdminService.updateLangKey(data)
      resolve(res)
    })
  },
  createAdminLangKey (__, data) {
    return new Promise(async resolve => {
      let res = await LangAdminService.createLangKey(data)
      resolve(res)
    })
  },
  exportAdminLang (__, key) {
    return new Promise(async resolve => {
      let res = await LangAdminService.exportLang(key)
      resolve(res)
    })
  },
  publishAdminLang (__, key) {
    return new Promise(async resolve => {
      let res = await LangAdminService.publish(key)
      resolve(res)
    })
  }
}

export default { actions }
