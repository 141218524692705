<template>
  <div id="app" class="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar"
    :class="{ 'closed-sidebar': !isOpenedSidebar, 'sidebar-mobile-open': isOpenedMBSidebar }">
    <router-view></router-view>
  </div>
</template>

<script>
import { loadLanguageAsync } from '@/plugin/i18n'
export default {
  name: 'App',
  computed: {
    isOpenedSidebar () {
      return this.$store.getters.isOpenedSidebar
    },
    isOpenedMBSidebar () {
      return this.$store.getters.isOpenedMBSidebar
    }
  },
  created () {
    loadLanguageAsync('en')
  }
}
</script>
