import CONFIG from '@/config/Config'
import Http from '@/plugin/axios.js'

export default {
  data () {
    return {
      query: {
        inviteCode: '',
        transactionId: '',
        status: '',
        type: ''
      },
    }
  },
  watch: {
    broker(n, o) {
      if (n) {
        if (this.$route.query.page !== 1 && o && n !== o) {
          let filterObj = this.buildQueryObj(true)
          filterObj.page = 1
          this.changeRouteParams(filterObj)
        } else this.fetch()
      }
    },
    $route () {
      this.fetch()
    }
  },
  computed: {
    users () {
      return this.$store.getters.users
    },
    pagination () {
      return this.$store.getters.pagination
    }
  },
  methods: {
    goToPage (page) {
      const temp = JSON.parse(JSON.stringify(this.pagination))
      temp.page = page
      this.$store.dispatch('setPagination', temp)
      let filterObj = this.buildQueryObj(true)
      filterObj.page = page
      this.changeRouteParams(filterObj)
    },
    search () {
      let query = this.query
      Object.keys(query).forEach(function(key) {
        switch (key) {
          case 'email':
            query[key] = query[key].trim()
            query[key] = query[key].toLowerCase()
          case 'fromAddress':
          case 'toAddress':
          case 'hash':
            query[key] = query[key].trim()
            break;
        }
      })
      let filterObj = Object.assign(this.buildQueryObj(true), query)
      filterObj.page = 1
      this.changeRouteParams(filterObj)
    },
    exportExcel (act) {
      let query = this.query
      Object.keys(query).forEach(function(key) {
        switch (key) {
          case 'email':
            query[key] = query[key].trim()
            query[key] = query[key].toLowerCase()
          case 'fromAddress':
          case 'toAddress':
          case 'hash':
            query[key] = query[key].trim()
            break;
        }
      })
      let params = Object.assign(this.buildQueryObj(), query)
      let apiUrl = `${CONFIG.API_POINT}/export/`+ act +`-to-excel`
      if (_.isEmpty(params.status)) {
        delete params.status
      }
      Object.keys(params).forEach(function(key) {
        if(key === 'size' || key === 'page' || params[key].length === 0 || params[key] === 'all' || params[key] === 'ALL' || params[key] === '' || params[key] === 'All') {
          delete params[key]
        } else if (key !== 'time' && typeof params[key] === 'object' && params[key].length) {
          params[key] = params[key].join(',')
        } else if (key === 'time' && params[key] && params[key].length) {
          params.startTime = params[key][0] ? params[key][0] : 0
          var endTime = params[key][1] && !isNaN(params[key][1]) ? new Date(Number(params[key][1])) : new Date()
          endTime.setDate(endTime.getDate() + 1)
          params.endTime = endTime.getTime()
          delete params[key]
        }
      })
      let fileName = act + '.xlsx'
      Http({
        url: apiUrl,
        method: 'GET',
        params: params,
        responseType: 'blob',
        headers: {
          'Accept': 'application/vnd.ms-excel'
        },
      }).then(res => {
        var blob = res
        if(window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          var downloadLink = window.document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
          downloadLink.download = fileName
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }
      })
    },
    buildQueryObj () {
      let filterObj = JSON.parse(JSON.stringify(this.$route.query))
      filterObj.page = this.$route.query.page ? this.$route.query.page : 1
      return filterObj
    },
    changeRouteParams (queryObj) {
      this.$router.push({ name: this.$route.name, query: queryObj })
    }
  }
}
